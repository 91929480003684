import axios from 'axios'
import moment from 'moment'
import { API } from '../ENV'

export const postPublicMessage = async ({ user, message, replyTo }) => {
  try {
    const response = await axios.post(
      `${API}/publicMessage/`,
      {
        user,
        message,
        replyTo
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPublicMessages = async ({ loadMore }) => {
  try {
    const response = await axios.get(
      `${API}/publicMessage/all/${loadMore || ''}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPublicMessagesSearch = async ({ loadMore, search }) => {
  const { text, startDate, endDate } = search

  try {
    const response = await axios.get(
      `${API}/publicMessage/search/${loadMore || '*'}/${text || '*'}/${
        startDate ? moment(startDate).toISOString() : '*'
      }/${endDate ? moment(endDate).toISOString() : '*'}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPublicMessageCounts = async (start, end) => {
  try {
    const response = await axios.get(
      `${API}/publicMessage/counts/${start}/${end}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPublicMessageUser = async (user) => {
  try {
    const response = await axios.get(`${API}/publicMessage/user/${user}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putPublicMessage = async ({ id, user, message }) => {
  try {
    const response = await axios.put(
      `${API}/publicMessage/`,
      {
        id,
        user,
        message
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const deletePublicMessage = async ({ id }) => {
  try {
    const response = await axios.delete(`${API}/publicMessage/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
