import React from 'react'
import CustomButton from './CustomButton.js'
import HubButton from './HubButton.js'
import WelcomeVideo from './WelcomeVideo.js'
import StreamOffline from './StreamOffline.js'
import PasswordReset from './PasswordReset.js'
import PhoneNumbers from './PhoneNumbers.js'
import { setPageTitle } from '../../../../utils/utilities.js'

const ContentGeneral = () => {
  setPageTitle('Content | General')
  return (
    <div className="grid xl:grid-cols-2 divide-x-1 divide-black/25 dark:divide-black/75 gap-2.5 mb-5">
      <div className="xl:p-5 flex flex-col gap-5 xl:bg-neutral-200/50 xl:dark:bg-neutral-800/50">
        <StreamOffline />
        <PhoneNumbers />
        <WelcomeVideo />
        <PasswordReset />
      </div>
      <div className="xl:p-5 flex flex-col gap-5 xl:bg-neutral-200/50 xl:dark:bg-neutral-800/50">
        <HubButton />
        <CustomButton />
      </div>
    </div>
  )
}

export default ContentGeneral
