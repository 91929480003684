import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { MEMBERS } from '../../../constants/chatRooms'
import * as ROLES from '../../../constants/roles'
import * as SETTINGS from '../../../constants/settings'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { setPageTitle } from '../../../utils/utilities'
import ToggleSwitchLocal from '../../Forms/common/ToggleSwitchLocal'
import MessageList from '../../Messages/MessageList'
import MessageWrapper from '../../Messages/MessageWrapper'
import PinnedMessage from '../../Utilities/PinnedMessage'
import { useViewportHeight } from '../../../hooks/useViewportHeight'

const ChatHistory = () => {
  useViewportHeight()
  setPageTitle('Chat History')

  const { user } = useContext(AuthenticationContext)
  const { updateSetting } = useContext(SiteConfigContext)
  const { [SETTINGS.PINNED_MESSAGE.key]: pinnedMessage } =
    useContext(SiteConfigContext)
  const [showPinnedMessage, setShowPinnedMessage] = useState(true)

  const [loading, setLoading] = useState(true)
  const defaultValue =
    user.role.role === ROLES.HOST
      ? false
      : JSON.parse(localStorage.getItem('showShadowBanned')) != null
        ? JSON.parse(localStorage.getItem('showShadowBanned'))
        : true
  const [showShadowBanned, setShowShadowBanned] = useState(defaultValue)
  const [search, setSearch] = useState(null)

  const handleSetShadowBan = () => {
    setLoading(true)
    setShowShadowBanned(!showShadowBanned)
  }

  useEffect(() => {
    setLoading(false)
  }, [showShadowBanned])

  const handleSearch = (e, value) => {
    e.preventDefault()
    setSearch(value)
  }

  const unPinMessage = async () => {
    await updateSetting(SETTINGS.PINNED_MESSAGE, null)
  }

  useEffect(() => {
    setShowPinnedMessage(true)
  }, [pinnedMessage])

  return (
    <div className="flex h-full w-full flex-col gap-2">
      <div className="flex flex-col w-full">
        {user.role.role !== ROLES.HOST && (
          <div className="mb-4 w-fit">
            <ToggleSwitchLocal
              setting="showShadowBanned"
              value={showShadowBanned}
              label="Show Shadow-Banned"
              color="green"
              setter={handleSetShadowBan}
            />
          </div>
        )}
        <div className="mb-4">
          <SearchForm handleSearch={handleSearch} />
        </div>
      </div>
      {showPinnedMessage && pinnedMessage && (
        <div className="flex flex-col w-full">
          <div className="w-full">
            <MessageWrapper
              index={0}
              message={pinnedMessage}
              channel={MEMBERS}
              modView={true}
            >
              <PinnedMessage
                message={pinnedMessage}
                unPin={unPinMessage}
                toggle={setShowPinnedMessage}
              />
            </MessageWrapper>
          </div>
        </div>
      )}
      {loading ? (
        <div />
      ) : (
        <MessageList
          channel={MEMBERS}
          modView={true}
          showShadowBanned={showShadowBanned}
          search={search}
        />
      )}
    </div>
  )
}

const SearchForm = ({ handleSearch }) => {
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const handleToggle = (e) => {
    if (!show === false) {
      handleSearch(e, null)
    }
    setShow(!show)
  }

  return (
    <div className="w-full">
      <button
        onClick={(e) => handleToggle(e)}
        aria-label="Toggle Search"
        className="button-confirm text-xs"
      >
        {!show ? 'Show Search' : 'Hide Search'}
      </button>
      {show && (
        <div className="w-full">
          <form
            onSubmit={(e) =>
              handleSearch(e, {
                text,
                startDate,
                endDate
              })
            }
            autoComplete="off"
            className="relative flex flex-col md:flex-row focus-within:z-10 gap-2 w-full mt-2"
          >
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Text</label>
              <input
                aria-label="Search messages"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="grow input w-full"
                placeholder="Search messages..."
              />
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Start Date</label>
              <input
                aria-label="Start Date"
                type="date"
                id="startDate"
                name="startDate"
                selected={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="grow input w-full"
              />
            </div>
            <div className=" flex flex-col">
              <label className="dark:text-neutral-100">End Date</label>
              <input
                aria-label="End Date"
                type="date"
                id="endDate"
                name="endDate"
                selected={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="grow input w-full"
              />
            </div>
            <button
              type="submit"
              aria-label="Submit search"
              className="button-confirm relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0 md:mt-auto w-fit"
            >
              Search
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func.isRequired
}

export default ChatHistory
