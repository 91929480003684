import { LOGIN_PORTAL_URL } from '@moal/api'
import React from 'react'
import { PRODUCT_CODE } from '../../../utils/ENV'
import { faSignIn } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LoginCommandCenter = ({ inHeader = false }) => {
  const handleOnClick = () => {
    window.location.href = `${LOGIN_PORTAL_URL}?from=${PRODUCT_CODE}`
  }

  return (
    <button
      className={`${inHeader ? 'header-button button-outline' : 'mx-auto w-full button-fill flex items-center justify-center gap-2 md:gap-0 p-2.5'}`}
      onClick={handleOnClick}
    >
      <span className={`${inHeader ? 'hidden md:block' : 'block text-lg'}`}>
        Sign in
      </span>
      <FontAwesomeIcon
        icon={faSignIn}
        className="header-button-icon"
        aria-hidden="true"
      />
    </button>
  )
}

export default LoginCommandCenter
