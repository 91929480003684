import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import CurrentStreamers from '../../Content/Streamers/CurrentStreamers'

const ActiveStreamer = (display) => {
  const {
    isLoading,
    updateSetting,
    [SETTINGS.STREAMERS.key]: streamers
  } = useContext(SiteConfigContext)

  if (isLoading) {
    return (
      <h1 className="flex items-center">
        Loading...{' '}
        <FontAwesomeIcon
          icon={faSpinner}
          className="h-4 w-4 ml-2 animate-spin"
        />
      </h1>
    )
  }

  const handleActivate = async (activate) => {
    const copy = streamers
    copy.forEach((each, index) => {
      each.active = index === activate
    })

    await updateSetting(SETTINGS.STREAMERS, copy)
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Set Active Streamer</h2>
      {streamers && (
        <CurrentStreamers
          streamers={streamers}
          handleActivate={handleActivate}
          display={display}
        />
      )}
    </div>
  )
}

export default ActiveStreamer
