import PropTypes from 'prop-types'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SocketContext } from '../../../context/socketContext'

import { PRIVATE_MESSAGE_EVENTS, TRADE_EVENTS } from '@moal/api'
import { MEMBERS, MODERATORS, TRADES } from '../../../constants/chatRooms'
import * as FEATURES from '../../../constants/features'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { putUser } from '../../../utils/APIs/users'
import { PRODUCT_CODE } from '../../../utils/ENV'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import {
  setPageTitleCounter,
  setPageTitleDefault
} from '../../../utils/utilities'

const ChatSectionNav = ({ active, handleSetChannel }) => {
  const { user, updateUser } = useContext(AuthenticationContext)
  const { privateMessagesSocket, tradesSocket } = useContext(SocketContext)
  const [modNotifCounter, setModNotifCounter] = useState(
    (user && user.notifications.privateMessages) || 0
  )
  const [tradeNotifCounter, setTradeNotifCounter] = useState(
    (user && user.notifications.trades) || 0
  )

  const { [SETTINGS.FEATURES.key]: enabledFeatures } =
    useContext(SiteConfigContext)
  const tradesEnabled = useMemo(
    () => enabledFeatures && enabledFeatures[FEATURES.TRADES],
    [enabledFeatures]
  )

  useEffect(() => {
    if (tradesSocket) {
      tradesSocket.on(TRADE_EVENTS.POST, handlePostTrade)
    }
    if (privateMessagesSocket) {
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.POST,
        handleNewPrivateMessage
      )
    }
    return () => {
      if (tradesSocket) {
        tradesSocket.off(TRADE_EVENTS.POST, handlePostTrade)
      }
      if (privateMessagesSocket) {
        privateMessagesSocket.off(
          PRIVATE_MESSAGE_EVENTS.POST,
          handleNewPrivateMessage
        )
      }
    }
  }, [tradesSocket, privateMessagesSocket, active])

  const handleNewPrivateMessage = () => {
    if (
      (active !== MODERATORS && !hasStaffPrivilege(user)) ||
      (!document.hasFocus() && !hasStaffPrivilege(user))
    ) {
      setModNotifCounter((modNotifCounter) => modNotifCounter + 1)
    }
  }

  useEffect(() => {
    if (user) {
      setModNotifCounter(user.notifications.privateMessages || 0)
      setTradeNotifCounter(user.notifications.trades || 0)
    }
  }, [user, user?.notifications])

  useEffect(() => {
    setPageTitleCounter(modNotifCounter + tradeNotifCounter)
  }, [modNotifCounter, tradeNotifCounter])

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [active, tradesSocket, modNotifCounter, tradeNotifCounter])

  const handlePostTrade = (data) => {
    if (data.itemNumber === PRODUCT_CODE) {
      if (active !== TRADES || !document.hasFocus()) {
        setTradeNotifCounter((tradeNotifCounter) => tradeNotifCounter + 1)
      }
      const alertSound = new Audio(
        // eslint-disable-next-line no-undef
        `https://s3.amazonaws.com/${process.env.REACT_APP_S3_BUCKET_NAME}/audio/trade-alert.mp3`
      )
      alertSound.volume = 0.5
      alertSound.play()
    }
  }

  const onFocus = () => {
    setPageTitleDefault()
    resetNotifCount(active)
  }

  const onBlur = () => {
    setPageTitleCounter(modNotifCounter + tradeNotifCounter)
  }

  const handleClick = (channel) => {
    handleSetChannel(channel)
    resetNotifCount(channel)
  }

  const resetNotifCount = async (type) => {
    let setting = ''
    let reset = false
    switch (type) {
      case MODERATORS:
        setting = 'privateMessages'
        reset = modNotifCounter > 0
        break
      case TRADES:
        setting = 'trades'
        reset = tradeNotifCounter > 0
        break
    }

    if (user && reset) {
      const response = await putUser(user._id, setting, 0, 'notifications')
      if (response && response.status === 200) {
        updateUser(response.data)
      }
    }
  }

  return (
    <div className="h-auto flex-none ">
      <div className=" overflow-visible flex h-full w-full flex-col justify-between">
        <ul className="flex w-full gap-0 md:gap-2 border-y md:border-y-0 border-brand-primary-600 divide-x divide-brand-primary-600 md:divide-x-0">
          <li className="w-full">
            <button
              onClick={() => handleClick(MEMBERS)}
              className={`chat-nav-button ${
                active === MEMBERS
                  ? 'chat-nav-button-active'
                  : 'chat-nav-button-inactive'
              }`}
            >
              Chat
            </button>
          </li>
          {tradesEnabled && (
            <li className="w-full">
              <button
                onClick={() => handleClick(TRADES)}
                className={`chat-nav-button ${
                  active === TRADES
                    ? 'chat-nav-button-active'
                    : 'chat-nav-button-inactive'
                }`}
              >
                <span className="inline">Trades</span>
                <div className="inline">
                  {tradeNotifCounter > 0 && (
                    <Notification count={tradeNotifCounter} />
                  )}
                </div>
              </button>
            </li>
          )}
          <li className="w-full">
            <button
              onClick={() => handleClick(MODERATORS)}
              className={`chat-nav-button ${
                active === MODERATORS
                  ? 'chat-nav-button-active'
                  : 'chat-nav-button-inactive'
              }`}
            >
              Mods
              {modNotifCounter > 0 && <Notification count={modNotifCounter} />}
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

ChatSectionNav.propTypes = {
  active: PropTypes.string.isRequired,
  handleSetChannel: PropTypes.func.isRequired
}

const Notification = ({ count }) => {
  return (
    <div
      className={`absolute mt-0.5 md:mt-1 lg:mt-0.5 ml-1 md:ml-0.5 lg:ml-1 inline-flex items-center justify-center rounded-full bg-yellow-500 h-4 md:h-3 lg:h-4 ${
        count > 9 ? 'w-6 md:w-4 lg:w-6' : 'w-4 md:w-3 lg:w-4'
      }`}
    >
      <span className="font-bold text-xs md:text-[9px] lg:text-xs text-black tabular-nums">
        {count > 9 ? '9+' : count}
      </span>
    </div>
  )
}

Notification.propTypes = {
  count: PropTypes.number.isRequired
}

export default ChatSectionNav
