import React, {
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import {
  videoPlayerReducer,
  initialState
} from '../../../reducers/videoPlayerReducer'
import {
  LIVE,
  RECONNECT,
  RESET
} from '../../../constants/actions/videoPlayerActions'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import './vjs-moal.css'
import { registerIVSTech, registerIVSQualityPlugin } from 'amazon-ivs-player'

registerIVSTech(videojs, {
  wasmWorker: '/amazon-ivs-wasmworker.min.js',
  wasmBinary: '/amazon-ivs-wasmworker.min.wasm'
})
registerIVSQualityPlugin(videojs)

const VideoSection = ({ modView }) => {
  const {
    [SETTINGS.LIVESTREAM_LIVE.key]: livestreamLive,
    [SETTINGS.STREAM_OFFLINE.key]: streamOffline,
    activeStreamer
  } = useContext(SiteConfigContext)

  return useMemo(() => {
    return (
      <div className="flex w-full sm:max-w-[640px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto aspect-video resize-helper">
        {(livestreamLive || modView) &&
        activeStreamer &&
        activeStreamer.IVSPlaybackURL ? (
          <StreamOnline
            key={activeStreamer.IVSPlaybackURL}
            IVSPlaybackURL={activeStreamer.IVSPlaybackURL}
          />
        ) : (
          <StreamOffline text={streamOffline.text} link={streamOffline.link} />
        )}
      </div>
    )
  }, [livestreamLive, streamOffline, activeStreamer])
}

VideoSection.propTypes = {
  modView: PropTypes.bool
}

const StreamOnline = ({ IVSPlaybackURL }) => {
  const [state, dispatch] = useReducer(videoPlayerReducer, initialState)

  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)

  const [muted, setMuted] = useState(false)
  const [browserBlocked, setBrowserblocked] = useState(false)

  let updater // uses a 5 second interval to check stream status

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js')
      videoRef.current.appendChild(videoElement)
      const player = (playerRef.current = videojs(
        videoElement,
        {
          techOrder: ['AmazonIVS'],
          autoplay: true,
          controls: true,
          // responsive: true,
          fill: true,
          playsinline: true
        },
        () => {
          // Play stream
          player.enableIVSQualityPlugin()
          player.src(IVSPlaybackURL)
          player.addClass('vjs-moal')
        }
      ))
    }
  }, [videoRef])

  useEffect(() => {
    if (state.reconnectAttempts > 0 && state.reconnectAttempts <= 5) {
      if (state.reconnecting === true && state.live === false) {
        updater = setTimeout(() => {
          playerRef.current.src(IVSPlaybackURL)
        }, 5000)
      }
    }

    return () => {
      clearTimeout(updater)
    }
  }, [state.reconnectAttempts])

  const handleReconnectAttempt = () => {
    dispatch({ type: RESET })
  }

  // const handleBuffering = () => {
  //   dispatch({ type: LIVE, payload: true })
  //   console.log('handleBuffering')
  // }

  const handleEnded = () => {
    dispatch({ type: RESET })
    // console.log('handleEnded')
  }

  // const handleIdle = () => {
  //   dispatch({ type: LIVE, payload: true })
  //   console.log('handleIdle')
  // }

  const handlePlaying = () => {
    dispatch({ type: LIVE, payload: true })
    unmuteStream()
  }

  // const handleReady = () => {
  //   dispatch({ type: LIVE, payload: true })
  //   console.log('handleReady')
  // }

  // const handlePlayerError = (error) => {
  //   console.log('handlePlayerError')
  //   console.log(error)
  // }

  const unmuteStream = () => {
    if (playerRef.current.muted()) {
      // Create the button element
      var button = document.createElement('button')

      // Set the button text
      button.innerHTML = 'Click Me'

      // Add an onclick event handler
      button.onclick = function () {
        playerRef.current.muted(false)
      }

      // Emulate a user clicking the button
      button.click()
    }
  }

  const handleAudioBlocked = () => {
    setBrowserblocked(true)
  }

  const handlePlaybackBlocked = () => {
    dispatch({ type: LIVE, payload: true })
  }

  const handleIVSError = (error) => {
    console.log('handleIVSError')
    console.log(error)
    dispatch({ type: RECONNECT, payload: { reconnecting: true } })
  }

  const handleVolumeChange = () => {
    if (playerRef.current.muted()) {
      setMuted(true)
    } else {
      setMuted(false)
    }
    // TODO: Check for Browser Tab Mute, not just videojs mute control
  }

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current
    const IVSPlayer = player.getIVSPlayer()
    const PlayerState = player.getIVSEvents().PlayerState
    const PlayerEvent = player.getIVSEvents().PlayerEventType

    player.on('volumechange', handleVolumeChange)
    // IVSPlayer.addEventListener(PlayerState.READY, handleReady)
    // IVSPlayer.addEventListener(PlayerState.IDLE, handleIdle)
    IVSPlayer.addEventListener(PlayerState.PLAYING, handlePlaying)
    // IVSPlayer.addEventListener(PlayerState.BUFFERING, handleBuffering)
    IVSPlayer.addEventListener(PlayerState.ENDED, handleEnded)
    IVSPlayer.addEventListener(PlayerEvent.ERROR, handleIVSError)

    IVSPlayer.addEventListener(PlayerEvent.AUDIO_BLOCKED, handleAudioBlocked)
    IVSPlayer.addEventListener(
      PlayerEvent.PLAYBACK_BLOCKED,
      handlePlaybackBlocked
    )

    // player.on('error', handlePlayerError)

    return () => {
      // IVSPlayer.removeEventListener(PlayerState.READY, handleReady)
      // IVSPlayer.removeEventListener(PlayerState.IDLE, handleIdle)
      IVSPlayer.removeEventListener(PlayerState.PLAYING, handlePlaying)
      // IVSPlayer.removeEventListener(PlayerState.BUFFERING, handleBuffering)
      IVSPlayer.removeEventListener(PlayerState.ENDED, handleEnded)
      IVSPlayer.removeEventListener(PlayerEvent.ERROR, handleIVSError)

      IVSPlayer.removeEventListener(
        PlayerEvent.AUDIO_BLOCKED,
        handleAudioBlocked
      )
      IVSPlayer.removeEventListener(
        PlayerEvent.PLAYBACK_BLOCKED,
        handlePlaybackBlocked
      )

      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div className="w-full h-full">
      <div className="relative w-full h-full bg-black">
        {!state.live && state.reconnectAttempts > 5 && (
          <NotificationBar
            message="Error Loading Stream"
            type="error"
            cta={'Reload Stream'}
            onClick={handleReconnectAttempt}
          />
        )}
        {!state.live && (
          <StreamOfflineContainer
            loading={!state.live && state.reconnectAttempts <= 5}
          />
        )}
        {state.live && muted && (
          <NotificationBar
            message="Warning: Audio is Muted"
            type="warning"
            cta={'Unmute'}
            onClick={() => {
              playerRef.current.muted(false)
              setMuted(false)
            }}
          />
        )}
        {state.live && browserBlocked && (
          <NotificationBar
            message="Autoplay of audio/video is blocked by your browser."
            type="warning"
            cta={'Help'}
            onClick={() => {
              window.open(
                '/audio-video-troubleshooting',
                '_blank',
                'noreferrer'
              )
            }}
          />
        )}
        <div
          className={`${state.live ? 'aspect-video resize-helper' : 'hidden'}`}
          ref={videoRef}
        />
      </div>
    </div>
  )
}

StreamOnline.propTypes = {
  IVSPlaybackURL: PropTypes.string
}

const StreamOfflineContainer = ({ loading }) => {
  return (
    <div className="flex aspect-video bg-black">
      <StreamOffline
        text={loading ? 'Loading...' : 'Stream failed to connect.'}
      />
    </div>
  )
}

StreamOfflineContainer.propTypes = {
  loading: PropTypes.bool
}

const NotificationBar = ({ onClick, cta, message, type }) => {
  let bgColor = ''
  switch (type) {
    case 'error':
      bgColor = 'bg-red-600'
      break
    case 'warning':
      bgColor = 'bg-amber-600'
      break
    default:
      bgColor = 'bg-nuetral-600'
  }
  const [show, setShow] = useState(true)
  const handleShow = () => {
    setShow(!show)
  }

  if (!show) {
    return null
  }

  return (
    <div
      className={`absolute z-10 flex w-fit my-2 ml-2 shadow-md shadow-black/25 justify-center items-center gap-2 ${bgColor} p-3 rounded-xl`}
    >
      <p className="text-xs lg:text-base font-bold text-white flex items-center gap-2">
        {message}
      </p>
      {onClick && cta && (
        <button className="notif-bar-button" onClick={onClick}>
          {cta}
        </button>
      )}
      {type !== 'error' && (
        <button onClick={() => handleShow()} className="notif-bar-dismiss">
          Dismiss
        </button>
      )}
    </div>
  )
}

NotificationBar.propTypes = {
  message: PropTypes.string.isRequired,
  cta: PropTypes.string,
  onClick: PropTypes.func
}

const StreamOffline = ({ text, link }) => {
  const { [SETTINGS.IMAGES.key]: images } = useContext(SiteConfigContext)

  const handleLink = () => {
    if (link) {
      window.open(link, '_blank', 'noreferrer')
    }
  }

  return (
    <div
      onClick={handleLink}
      className={`m-auto flex flex-col justify-center items-center w-full  ${
        link && 'cursor-pointer'
      }`}
    >
      <div className="flex flex-col items-center my-auto w-full gap-4">
        <img
          className="mx-auto w-3/4 lg:w-3/5 xl:w-1/2 object-contain"
          src={images.offline}
          alt="Stream Offline"
        />
        <h3 className="text-center text-sm font-medium text-neutral-300 md:text-xl tracking-wide max-w-sm md:max-w-xl">
          {text}
        </h3>
      </div>
    </div>
  )
}

StreamOffline.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
}

export default VideoSection
