import React from 'react'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SearchButton = () => {
  return (
    <button type="submit" aria-label="Submit search" className="button-confirm">
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </button>
  )
}

export default SearchButton
