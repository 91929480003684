import React, { useContext } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'

const StreamOffline = () => {
  const { [SETTINGS.STREAM_OFFLINE.key]: offline, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      text: formData.get('text'),
      link: formData.get('link')
    }
    await updateSetting(SETTINGS.STREAM_OFFLINE, data)
    toast.success('Stream Offline Text saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Stream Offline Text</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="text"
          name="text"
          defaultValue={offline.text}
          className="input block w-full"
          placeholder="Offline Text"
          required
        />
        <input
          id="link"
          name="link"
          defaultValue={offline.link}
          className="input block w-full"
          placeholder="Offline Link"
        />
        <button type="submit" className="button-confirm w-fit">
          Save
        </button>
      </form>
    </div>
  )
}

export default StreamOffline
