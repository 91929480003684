import { postOxforce } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { PRODUCT_CODE } from '../../../utils/ENV'

const defaultValues = {
  name: '',
  email: '',
  body: '',
  phone: '',
  itemNumber: PRODUCT_CODE
}

const Oxforce = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    state: false,
    message: ''
  })

  const [data, setData] = useState(defaultValues)

  useEffect(() => {
    if (user) {
      setData({
        ...data,
        name: user.userService.profile.name,
        email: user.userService.email
      })
    }
  }, [user])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    toast.success('Sent to Oxforce.')
    setError({
      state: false,
      message: ''
    })

    const response = await postOxforce({ data })
    if (!response || response.status !== 200) {
      setError({
        state: true,
        message: response.data.message
      })
    }
    setLoading(false)
  }

  return (
    <div className="mx-auto w-full">
      <div className="overflow-y-auto">
        <form
          onSubmit={handleSubmit}
          autoComplete="off"
          className="w-full flex flex-col gap-1"
        >
          <ul className="w-fit flex flex-col gap-2">
            <li className="text-sm">
              <span className="font-semibold">Display Name:</span> {data.name}
            </li>
            <li className="text-sm">
              <span className="font-semibold">Source:</span> {data.itemNumber}
            </li>
          </ul>

          <label className="flex gap-2 items-center dark:text-neutral-50">
            <span className="font-semibold text-sm">Email:</span>
            <input
              value={data.email}
              onChange={(e) =>
                setData((data) => ({ ...data, email: e.target.value }))
              }
              type="email"
              placeholder="Email address"
              required
              className="input w-full"
            />
          </label>

          <label className="flex gap-2 items-center dark:text-neutral-50">
            <span className="font-semibold text-sm">Phone:</span>
            <input
              value={data.phone}
              onChange={(e) =>
                setData((data) => ({ ...data, phone: e.target.value }))
              }
              type="tel"
              placeholder="555-666-7777"
              className="input w-full"
            />
          </label>

          <label className="flex gap-2 items-center dark:text-neutral-50">
            <span className="font-semibold text-sm">Notes:</span>
          </label>
          <textarea
            value={data.body}
            onChange={(e) =>
              setData((data) => ({ ...data, body: e.target.value }))
            }
            className="input"
            required
          />

          <div className="flex flex-col">
            {error.state && <p className="text-red-400">{error.message}</p>}
            <input
              type="submit"
              className="button-confirm"
              value={loading ? 'Submitting...' : 'Submit'}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

Oxforce.propTypes = {
  user: PropTypes.object.isRequired
}

export default Oxforce
