import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../constants/settings'
import ToggleSwitch from '../../Forms/common/ToggleSwitch'
import VideoSection from '../../../components/Layout/VideoSection'

import { delay, setPageTitle } from '../../../utils/utilities'
import {
  modSettingsReducer,
  initialState
} from '../../../reducers/modSettingsReducer'
import {
  LOADING,
  LIVE_CONFIRMATION,
  INITIALIZE,
  TITLE,
  RESEND_CONFIRMATION
} from '../../../constants/actions/modSettingsActions'
import { toast } from 'react-toastify'
import ActiveStreamer from './ActiveStreamer'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { putGoLive } from '../../../utils/APIs/settings'

const TITLE_MAX_LENGTH = 140

const SiteSettings = () => {
  setPageTitle('Site Settings')
  const {
    [SETTINGS.LIVESTREAM_LIVE.key]: livestreamLive,
    [SETTINGS.CHAT_OPEN.key]: chatOpen,
    [SETTINGS.STREAM_TITLE.key]: streamTitle
  } = useContext(SiteConfigContext)
  const [state, dispatch] = useReducer(modSettingsReducer, initialState)

  useEffect(() => {
    dispatch({
      type: INITIALIZE,
      payload: {
        live: livestreamLive,
        open: chatOpen,
        title: streamTitle
      }
    })
  }, [livestreamLive, chatOpen, streamTitle])

  useEffect(() => {
    if (streamTitle) {
      dispatch({ type: TITLE, payload: streamTitle })
    }
  }, [streamTitle])

  return (
    <div className="flex w-full gap-2">
      <div className="flex flex-col lg:flex-row w-full gap-5">
        <div className="flex flex-col gap-5 lg:w-1/2 xl:w-2/3">
          <h2>Livestream Settings</h2>
          <Settings state={state} dispatch={dispatch} />
          <h2>Incoming Feed</h2>
          <VideoSection modView />
        </div>
        <div className="h-fit lg:w-1/2 xl:w-1/3 xl:p-5 flex flex-col gap-5 xl:bg-neutral-200/50 xl:dark:bg-neutral-800/50">
          <ActiveStreamer display="flex" />
        </div>
      </div>
    </div>
  )
}

const Settings = ({ state, dispatch }) => {
  const { updateSetting } = useContext(SiteConfigContext)

  const handleTitleChange = (value) => {
    dispatch({ type: TITLE, payload: value })
  }

  const handleLiveConfirmationChange = (value) => {
    dispatch({ type: LIVE_CONFIRMATION, payload: value })
  }

  const handleResendConfirmationChange = (value) => {
    dispatch({ type: RESEND_CONFIRMATION, payload: value })
  }

  const handleTitle = async (e) => {
    e.preventDefault()
    await updateSetting(SETTINGS.STREAM_TITLE, state.title)
    toast.success('Stream Title updated.')
  }

  const handleGoLive = async (status) => {
    dispatch({ type: LOADING, payload: true })
    dispatch({ type: LIVE_CONFIRMATION, payload: false })
    dispatch({ type: RESEND_CONFIRMATION, payload: false })
    const response = await putGoLive(status, state.title)
    if (response && response.status === 200) {
      await delay(5000) // delay so they can't spam it
      dispatch({ type: LOADING, payload: false })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 w-fit">
        <ToggleSwitch
          setting={SETTINGS.LIVESTREAM_LIVE}
          value={state.live}
          label="Livestream Live"
          color="green"
        />
        <ToggleSwitch
          setting={SETTINGS.CHAT_OPEN}
          value={state.open}
          label="Chat Open"
          color="green"
        />
      </div>
      <h4>
        Stream Title
        <span className="ml-2 text-xs">
          <span
            className={
              state.title.length === TITLE_MAX_LENGTH ? 'text-red-500' : ''
            }
          >
            ({state.title.length} / {TITLE_MAX_LENGTH})
          </span>
        </span>
      </h4>
      <form onSubmit={handleTitle} autoComplete="off">
        <div className="relative flex flex-grow items-stretch focus-within:z-10 gap-2">
          <input
            value={state.title}
            onChange={(e) => handleTitleChange(e.target.value)}
            className="input block w-full"
            placeholder="Stream Title"
            maxLength={TITLE_MAX_LENGTH}
          />
          <button
            type="submit"
            aria-label="Submit title"
            className="button-confirm inline-flex items-center gap-x-1.5 text-base"
          >
            Set Title
          </button>
        </div>
      </form>
      <div className="flex flex-col md:flex-row gap-2">
        {!state.liveConfirmation && (
          <button
            onClick={() => handleLiveConfirmationChange(true)}
            aria-label={!state.live ? 'Go Live' : 'End Stream'}
            className={`${
              !state.live ? 'button-confirm' : 'button-cancel'
            } w-1/2 text-base`}
            disabled={state.loading}
          >
            {!state.live ? 'Go Live' : 'End Stream'}
          </button>
        )}
        {state.liveConfirmation && (
          <div className="flex w-1/2 gap-2 items-center justify-center rounded-md bg-neutral-200 dark:bg-neutral-800 p-2">
            <h5 className="font-bold">
              {!state.live ? 'Go Live?' : 'End Stream?'}
            </h5>
            <div className="flex gap-2 w-fit ml-2 items-center">
              <button
                onClick={() => handleGoLive(!state.live)}
                aria-label="Confirm"
                className="button-confirm rounded-full py-0.5 px-1"
              >
                <FontAwesomeIcon icon={faCheck} className="w-4 h-4" />
              </button>
              <button
                onClick={() => handleLiveConfirmationChange(false)}
                aria-label="Cancel"
                className="button-cancel rounded-full py-0.5 px-1"
              >
                <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
              </button>
            </div>
          </div>
        )}
        {state.loading && (
          <div className="text-green-500 my-auto">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}
        {state.live && (
          <>
            {!state.resendConfirmation && (
              <button
                onClick={() => handleResendConfirmationChange(true)}
                aria-label="Resend Notification"
                className="button-fill-neutral w-1/2 text-base"
                disabled={state.loading}
              >
                {state.loading
                  ? 'Sending Notification...'
                  : 'Resend Notification'}
              </button>
            )}
            {state.resendConfirmation && (
              <div className="flex w-1/2 gap-2 items-center justify-center rounded-md bg-neutral-200 dark:bg-neutral-800 p-2">
                <h5 className="font-bold">Resend?</h5>
                <div className="flex gap-2 w-fit ml-2 items-center">
                  <button
                    onClick={() => handleGoLive(true)}
                    aria-label="Confirm"
                    className="button-confirm rounded-full py-0.5 px-1"
                  >
                    <FontAwesomeIcon icon={faCheck} className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleResendConfirmationChange(false)}
                    aria-label="Cancel"
                    className="button-cancel rounded-full py-0.5 px-1"
                  >
                    <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
                  </button>
                </div>
              </div>
            )}
            {state.loading && (
              <div className="text-green-500 my-auto">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

SiteSettings.propTypes = {
  state: PropTypes.any,
  dispatch: PropTypes.any
}

export default SiteSettings
