import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PRODUCT_TYPES } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import * as SETTINGS from '../../../constants/settings'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { appendStoredQueryParams } from '../../../utils/utilities'
import Countdown from '../Countdown'

const AdBanner = ({ toggleAd }) => {
  const {
    productsService,
    [SETTINGS.FEATURES.key]: enabledFeatures,
    [SETTINGS.BANNER_DATA.key]: bannerData
  } = useContext(SiteConfigContext)
  const { getSubType } = useContext(AuthenticationContext)
  const [subType] = useState(getSubType())

  if (!bannerData) {
    return null
  }

  if (
    bannerData.openHouseOnly &&
    enabledFeatures &&
    productsService[PRODUCT_TYPES.PRODUCT].details?.openHouse?.enabled &&
    productsService[PRODUCT_TYPES.PRODUCT].details?.openHouse?.itemNumber !==
      subType
  ) {
    return null
  }

  const bgColor = bannerData.bgColor
    ? `bg-${bannerData.bgColor}-400 border-${bannerData.bgColor}-500`
    : 'bg-yellow-400 border-yellow-500'
  const buttonColor = bannerData.buttonColor
    ? `bg-${bannerData.buttonColor}-500 border-${bannerData.buttonColor}-600`
    : 'bg-green-500 border-green-600'
  const showToggle = toggleAd && bannerData.showCountdown
  const adBodyClassName = `flex flex-col items-center justify-center w-full gap-1 px-4 cursor-pointer`

  const handleToggleAd = () => {
    toggleAd(false)
  }

  const handleOpenLink = () => {
    const linkWParams = appendStoredQueryParams(bannerData.promoLink)
    window.open(linkWParams, '_blank')
  }

  return (
    <div className="relative">
      <button onClick={handleToggleAd} className="button-x">
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div
        className={`${bgColor} flex flex-col p-2 justify-center items-center md:rounded-xl shadow-md border text-sm md:text-base`}
      >
        {showToggle && (
          <div className="mx-auto ">
            <span className="flex items-center font-bold italic text-xs md:text-sm gap-1">
              {bannerData.countdownText}
              <Countdown
                endDate={bannerData.endDateTime}
                endEvent={handleToggleAd}
              />
            </span>
          </div>
        )}
        <div className={adBodyClassName} onClick={handleOpenLink}>
          <p className="text-black text-base md:text-lg w-fit">
            {bannerData.body}
          </p>
          {bannerData.showButton && (
            <button
              className={`${buttonColor} border rounded px-2 py-1 font-bold whitespace-nowrap transition-all duration-300 hover:shadow-button hover:scale-[1.05] text-black`}
            >
              {bannerData.buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

AdBanner.propTypes = {
  toggleAd: PropTypes.func
}

export default AdBanner
