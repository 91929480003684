import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

const CountdownBar = ({
  timeInSeconds,
  framesPerSecond,
  onComplete = null,
  onCancel = null
}) => {
  const progressBarRef = useRef(null)
  const animationFrameRef = useRef(null)

  // const frameDuration = 1000 / framesPerSecond
  const totalFrames = Math.ceil(timeInSeconds * framesPerSecond)
  let currentFrame = 0

  const updateProgress = () => {
    if (progressBarRef.current) {
      const progress = currentFrame / totalFrames
      const invertedProgress = 1 - progress
      progressBarRef.current.style.width = `${invertedProgress * 100}%`

      if (currentFrame >= totalFrames) {
        cancelAnimationFrame(animationFrameRef.current)
        if (onComplete) {
          onComplete()
        }
      } else {
        currentFrame += 1
        animationFrameRef.current = requestAnimationFrame(updateProgress)
      }
    }
  }

  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(updateProgress)

    return () => {
      cancelAnimationFrame(animationFrameRef.current)
    }
  }, [])

  return (
    <div className="hidden md:flex flex-col p-1">
      <div className="flex items-center">
        <div className="relative h-2 w-full bg-neutral-200 rounded-lg overflow-hidden">
          <div
            ref={progressBarRef}
            className="absolute h-full bg-blue-500"
            style={{ width: '100%' }}
          />
        </div>
        {onCancel && (
          <div>
            <button onClick={onCancel} className="flex items-center">
              <FontAwesomeIcon
                className="mt-1 mr-1 h-5 transition-all hover:text-red-600 active:text-red-800 dark:text-white"
                icon={faTimesCircle}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

CountdownBar.propTypes = {
  timeInSeconds: PropTypes.number.isRequired,
  framesPerSecond: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default CountdownBar
