import React, { useContext } from 'react'
import Footer from '../../components/Layout/Footer'
import Header from '../../components/Layout/Header'
import { AuthenticationContext } from '../../context/authenticationContext'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { setPageTitle } from '../../utils/utilities'
import Free from './Free'
import Information from './Information'
import UserSettings from './UserSettings'

const Headquarters = () => {
  setPageTitle('Headquarters')
  const { user } = useContext(AuthenticationContext)
  const { freeEnabled } = useContext(SiteConfigContext)

  const hasSignedUp =
    user && user.onboarding.terms && user.userService.profile.name.length > 0

  return (
    <main className="flex h-screen max-h-screen flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900">
      <Header />
      <div className="flex flex-col md:flex-row flex-1 overflow-y-auto md:gap-4 md:px-4 md:justify-center container mx-auto">
        {user && hasSignedUp && (
          <Column>
            <UserSettings />
          </Column>
        )}
        {freeEnabled && !hasSignedUp && (
          <Column>
            <Free />
          </Column>
        )}
        <Column>
          <Information />
        </Column>
      </div>
      <div className="p-2">
        <Footer />
      </div>
    </main>
  )
}

const Column = ({ children }) => {
  return (
    <div className="flex flex-col gap-4 md:mt-4 md:mb-auto p-4 md:p-10 w-full  bg-white dark:bg-black rounded md:shadow-lg">
      {children}
    </div>
  )
}

export default Headquarters
