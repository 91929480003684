import {
  getModChatIsOpen,
  PRIVATE_MESSAGE_EVENTS,
  toggleModChatIsOpen
} from '@moal/api'
import React, { useContext, useEffect, useState } from 'react'
import { SocketContext } from '../../../context/socketContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'

export const CommandCenterControls = () => {
  const [isChecked, setIsChecked] = useState(false)
  const { privateMessagesSocket } = useContext(SocketContext)

  useEffect(() => {
    const getCommandCenterModChat = async () => {
      const response = await getModChatIsOpen({
        token: localStorage.getItem('authToken')
      })
      if (response && response.status === 200) {
        setIsChecked(response.data.isOpen)
      }
    }
    getCommandCenterModChat()
  }, [])

  useEffect(() => {
    if (privateMessagesSocket) {
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.TOGGLE_MOD_CHAT,
        handleToggle
      )
    }

    return () => {
      if (privateMessagesSocket) {
        privateMessagesSocket.off(
          PRIVATE_MESSAGE_EVENTS.TOGGLE_MOD_CHAT,
          handleToggle
        )
      }
    }
  }, [privateMessagesSocket])

  const handleToggle = async ({ isOpen = null }) => {
    if (typeof isOpen === 'boolean') {
      setIsChecked(isOpen)
      return
    } else {
      const response = await toggleModChatIsOpen({
        token: localStorage.getItem('authToken')
      })
      if (response && response.status === 200) {
        setIsChecked(response.data.isOpen)
      }
    }
  }

  return (
    <div>
      <label
        className={`cursor-pointer flex w-fit items-center justify-between px-2 py-1 gap-1.5 rounded border transition-all ${
          isChecked
            ? 'bg-green-500/15 hover:bg-green-500/25 border-green-500/50'
            : 'bg-red-500/15 hover:bg-red-500/25 border-red-500/50'
        }`}
      >
        <FontAwesomeIcon
          icon={isChecked ? faCircleCheck : faCircleXmark}
          className={`${isChecked ? 'text-green-500' : 'text-red-500'}`}
        />{' '}
        <p className="select-none">
          {isChecked
            ? 'Command Center Mod Chat is open'
            : 'Command Center Mod Chat is closed'}
        </p>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
          className="hidden"
        />
      </label>
    </div>
  )
}

export default CommandCenterControls
