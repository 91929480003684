import React, { useState } from 'react'
import { putProduct } from '@moal/api'
import { toast } from 'react-toastify'
import ToggleSwitchBasic from '../../../Forms/common/ToggleSwitchBasic'

const OpenHouse = ({ product, updateState }) => {
  const [openHouse, setOpenHouse] = useState(
    product.details.openHouse || {
      enabled: false,
      itemNumber: '',
      startDate: '',
      endDate: ''
    }
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    const updatedProduct = {
      ...product,
      details: { ...product.details, openHouse }
    }
    try {
      await putProduct({
        token: localStorage.getItem('authToken'),
        data: updatedProduct
      })
      updateState(updatedProduct)
      toast.success('Open House settings saved successfully')
    } catch (error) {
      toast.error('Failed to save Open House settings')
    }
  }

  return (
    <div className="flex flex-col gap-5 p-6 bg-white dark:bg-neutral-900 rounded-lg shadow-md w-fit">
      <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
        Open House
      </h2>
      <form onSubmit={handleSubmit} className="w-96 space-y-4">
        <ToggleSwitchBasic
          value={openHouse.enabled}
          label="Enabled"
          color="green"
          onChange={(value) =>
            setOpenHouse((prev) => ({ ...prev, enabled: value }))
          }
        />
        {openHouse.enabled && (
          <>
            <div>
              <label
                className="block text-sm font-medium text-gray-700 dark:text-white"
                htmlFor="itemNumber"
              >
                Open House Item Number (Pub Code)
              </label>
              <input
                id="itemNumber"
                name="itemNumber"
                value={openHouse.itemNumber}
                onChange={(e) =>
                  setOpenHouse((prev) => ({
                    ...prev,
                    itemNumber: e.target.value
                  }))
                }
                className="input mt-1 w-full"
                placeholder="Open House Item Number"
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium text-gray-700 dark:text-white"
                htmlFor="start"
              >
                Start
              </label>
              <input
                id="startDate"
                name="startDate"
                type="date"
                value={openHouse.startDate}
                onChange={(e) =>
                  setOpenHouse((prev) => ({
                    ...prev,
                    startDate: e.target.value
                  }))
                }
                className="input mt-1 w-full"
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium text-gray-700 dark:text-white"
                htmlFor="end"
              >
                End
              </label>
              <input
                id="endDate"
                name="endDate"
                type="date"
                value={openHouse.endDate}
                onChange={(e) =>
                  setOpenHouse((prev) => ({
                    ...prev,
                    endDate: e.target.value
                  }))
                }
                className="input mt-1 w-full"
              />
            </div>
          </>
        )}
        <button type="submit" className="w-full button-confirm">
          Save
        </button>
      </form>
    </div>
  )
}

export default OpenHouse
