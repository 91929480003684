import React, { useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InitializeSettings from '../../components/Moderation/InitializeSettings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import * as SETTINGS from '../../constants/settings'
import { setPageTitle } from '../../utils/utilities'
import Footer from '../../components/Layout/Footer'

const Initialize = () => {
  setPageTitle('Initialize')
  const { isLoading, [SETTINGS.SETUP_COMPLETE.key]: setupComplete } =
    useContext(SiteConfigContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (setupComplete) {
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
    }
  }, [setupComplete])

  if (isLoading) {
    return <div>LOADING...</div>
  }

  return (
    <main className="flex flex-col h-screen overflow-y-auto xl:p-5 bg-primary-gradient">
      <div className="flex flex-col gap-5 lg:w-2/3 xl:w-1/2 justify-center mb-auto md:m-auto">
        <div className="container sm:rounded-lg bg-neutral-100 dark:bg-neutral-900 w-fit mx-auto p-5 shadow-lg">
          <div className="flex flex-col justify-between items-center gap-5">
            <InitializeSettings />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </main>
  )
}

export default Initialize
