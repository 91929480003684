import {
  getTradesByItemNumber,
  getTradesBySearch,
  TRADE_EVENTS
} from '@moal/api'
import { useContext, useEffect } from 'react'
import {
  ADD_MESSAGE,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  INITIAL_LOAD,
  LOAD_MORE
} from '../constants/actions/messageListActions'
import { TRADES } from '../constants/chatRooms'
import { SocketContext } from '../context/socketContext'
import { PRODUCT_CODE } from '../utils/ENV'

export const useTradeService = ({ dispatch, channel }) => {
  const { tradesSocket } = useContext(SocketContext)

  useEffect(() => {
    if (channel === TRADES && tradesSocket) {
      tradeSockets()
    } else {
      tradeSockets(false)
    }
    return () => {
      tradeSockets(false)
    }
  }, [tradesSocket, channel])

  const tradeSockets = (on = true) => {
    if (on) {
      tradesSocket.on(TRADE_EVENTS.POST, handlePostTrade)
      tradesSocket.on(TRADE_EVENTS.PUT, handleEditTrade)
      tradesSocket.on(TRADE_EVENTS.DELETE, handleDeleteTrade)
    } else {
      if (tradesSocket) {
        tradesSocket.off(TRADE_EVENTS.POST, handlePostTrade)
        tradesSocket.off(TRADE_EVENTS.PUT, handleEditTrade)
        tradesSocket.off(TRADE_EVENTS.DELETE, handleDeleteTrade)
      }
    }
  }

  const handleGetTrades = async ({ initialLoad, messages, search }) => {
    let loadMore = ''
    if (!initialLoad && messages.length > 0) {
      loadMore = messages[messages.length - 1].createdAt
    }

    let response
    if (search) {
      response = await getTradesBySearch({
        search,
        itemNumber: PRODUCT_CODE,
        loadMore
      })
    } else {
      response = await getTradesByItemNumber({
        itemNumber: PRODUCT_CODE,
        loadMore
      })
    }

    if (response && response.status === 200) {
      if (initialLoad) {
        dispatch({ type: INITIAL_LOAD, payload: response.data })
      } else {
        dispatch({ type: LOAD_MORE, payload: response.data })
      }
    }
  }

  const handlePostTrade = (data) => {
    if (data.itemNumber === PRODUCT_CODE) {
      dispatch({ type: ADD_MESSAGE, payload: data })
    }
  }

  const handleEditTrade = (data) => {
    if (data.itemNumber === PRODUCT_CODE) {
      dispatch({ type: EDIT_MESSAGE, payload: data })
    }
  }

  const handleDeleteTrade = (data) => {
    if (data.itemNumber === PRODUCT_CODE) {
      dispatch({ type: DELETE_MESSAGE, payload: data })
    }
  }

  return { handleGetTrades }
}
