import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '../../../../context/authenticationContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

import { uploadToS3 } from '../../../../utils/S3ImageUploads'

const UploadImages = ({ returnImages, single = false }) => {
  if (single) {
    return (
      <UploadSingleImage uploadToS3={uploadToS3} returnImages={returnImages} />
    )
  }

  return (
    <UploadMultipleImages uploadToS3={uploadToS3} returnImages={returnImages} />
  )
}

UploadImages.propTypes = {
  returnImages: PropTypes.func.isRequired,
  single: PropTypes.bool
}

const UploadSingleImage = ({ uploadToS3, returnImages }) => {
  const { user } = useContext(AuthenticationContext)
  const [loading, setLoading] = useState(false)

  const addImage = (image) => {
    setLoading(true)
    uploadImage(image)
  }

  const uploadImage = async (file) => {
    const imageURL = await uploadToS3({ file, user })
    returnImages(imageURL)
    setLoading(false)
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <input
          type="file"
          name="myImage"
          onChange={(event) => {
            addImage(event.target.files[0])
          }}
          className="flex-1 input"
        />
        {loading && (
          <div className="flex items-center bg-green-500/20 border border-green-500 ml-2 p-3 rounded font-medium text-green-500 transition-all text-sm">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}
      </div>
    </div>
  )
}

UploadSingleImage.propTypes = {
  uploadToS3: PropTypes.func.isRequired,
  returnImages: PropTypes.func.isRequired
}

const UploadMultipleImages = ({ uploadToS3, returnImages }) => {
  const { user } = useContext(AuthenticationContext)
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [imageStrings, setImageStrings] = useState([])

  const addImage = (image) => {
    setLoading(true)
    const found = images.find((each) => each.name === image.name)
    if (found) {
      const replace = images.map((each) =>
        each.name === image.name ? image : each
      )
      setImages(replace)
    } else {
      setImages([...images, image])
    }
    uploadImage(image)
  }

  const uploadImage = async (file) => {
    const imageURL = await uploadToS3({ file, user })

    returnImagesArray({
      add: {
        name: file.name,
        url: imageURL
      }
    })
    setLoading(false)
  }

  const removeImage = (image) => {
    const newImages = images.filter((each) => each.name !== image.name)
    setImages(newImages)

    returnImagesArray({ remove: image.name })
  }

  const returnImagesArray = ({ add, remove }) => {
    let clone = imageStrings

    if (add) {
      const found = imageStrings.find((each) => each.name === add.name)
      if (found) {
        clone = clone.map((each) => (each.name === add.name ? add : each))
      } else {
        clone.push(add)
      }
    }

    if (remove) {
      clone = clone.filter((each) => each.name !== remove)
    }

    setImageStrings(clone)

    const justStrings = clone.map((each) => {
      return each.url
    })

    returnImages(justStrings)
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-3 gap-2">
        {images &&
          images.map((img) => {
            return (
              <div
                key={img.name}
                className="flex flex-col border p-1 rounded dark:border-neutral-700 bg-neutral-200 dark:bg-neutral-800 h-fit gap-2"
              >
                <img
                  alt="not found"
                  src={URL.createObjectURL(img)}
                  className="w-32 rounded"
                />
                <button
                  onClick={() => removeImage(img)}
                  className="w-fit text-xs button-cancel"
                >
                  Remove
                </button>
              </div>
            )
          })}
      </div>
      <div className="flex">
        <input
          type="file"
          name="myImage"
          onChange={(event) => {
            addImage(event.target.files[0])
          }}
          className="flex-1 input"
        />
        {loading && (
          <div className="flex items-center bg-green-500/20 border border-green-500 ml-2 p-3 rounded font-medium text-green-500 transition-all text-sm">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}
      </div>
    </div>
  )
}

UploadMultipleImages.propTypes = {
  uploadToS3: PropTypes.func.isRequired,
  returnImages: PropTypes.func.isRequired
}

export default UploadImages
