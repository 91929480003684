import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSortBy, useExpanded, usePagination, useTable } from 'react-table'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { getUserDump } from '../../../utils/APIs/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronDown,
  faSpinner
} from '@fortawesome/pro-solid-svg-icons'
import { setPageTitle } from '../../../utils/utilities'

const TABLE_ICON_CLASSES =
  'w-5 h-5 md:w-3.5 md:h-3.5 ml-2 text-lime-500 dark:text-lime-400 hover:text-lime-700 dark:hover:text-lime-300'

const UserDump = () => {
  setPageTitle('Admin | User Dump')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [users, setUsers] = useState([])
  const [downloadData, setDownloadData] = useState([])

  const expandIcon = (
    <FontAwesomeIcon icon={faChevronRight} className={TABLE_ICON_CLASSES} />
  )
  const condenseIcon = (
    <FontAwesomeIcon icon={faChevronDown} className={TABLE_ICON_CLASSES} />
  )

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async () => {
    const response = await getUserDump()
    if (response && response.status === 200) {
      processData(response.data)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  const processData = (data) => {
    const newdata = data.map((e, i) => {
      return {
        ...data[i],
        subscriptions: createJSONCell(e.userService.subscriptions),
        registrationDate: createDateCell(e.registrationDate)
      }
    })
    setUsers(newdata)
  }

  const createDateCell = (value) => {
    if (value) {
      return moment(value).format('MM/DD/YYYY')
    } else {
      return ''
    }
  }

  const createJSONCell = (value) => {
    if (value) {
      let data = ''
      value.forEach((each) => {
        data += `${each.itemNumber}:${each.subType} | `
      })
      return data
    } else {
      return ''
    }
  }

  // use memoize functions for react-tables
  // useTable expects "data"
  const data = useMemo(() => users || [], [users])

  // set column headers and map to data (accessor)
  const columns = useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        className: 'w-8',
        Header: '',
        AltHeader: 'Expand Subtrades',
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? condenseIcon : expandIcon}
            </span>
          ) : null,
        propTypes: {
          getToggleAllRowsExpandedProps: PropTypes.any,
          isAllRowsExpanded: PropTypes.any,
          row: PropTypes.any
        } // tbh i have no clue but it stops proptypes from yelling at me
      },
      {
        Header: 'User Service ID',
        accessor: 'userService._id',
        headerClassName: 'md:w-32 lg:w-48 py-3',
        className: 'md:w-32 lg:w-48 py-3'
      },
      {
        Header: 'Local ID',
        accessor: '_id',
        headerClassName: 'md:w-32 lg:w-48 py-3',
        className: 'md:w-32 lg:w-48 py-3'
      },
      {
        Header: 'Customer Number',
        accessor: 'userService.customerNumber',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums'
      },
      {
        Header: 'Name',
        accessor: 'userService.profile.name',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums'
      },
      {
        Header: 'Email',
        accessor: 'userService.email',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums'
      },
      {
        Header: 'Registration Date',
        accessor: 'registrationDate',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums'
      },
      {
        Header: 'Current Subs',
        accessor: 'subscriptions',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums'
      }
    ],
    []
  )

  const getRowClasses = (row) => {
    let classes =
      'even:bg-neutral-100 dark:even:bg-neutral-800 odd:bg-neutral-50 dark:odd:bg-neutral-900'
    classes += row.depth === 0 && row.subRows.length === 0 ? ' no-sub-rows' : ''
    classes += row.depth === 0 && row.isExpanded ? getExpandedRowBorder() : ''
    classes += row.depth !== 0 ? getSubRowBorder(row) : ''
    return classes
  }

  const getCellClasses = (row, cell, index) => {
    let classes = 'text-left text-sm py-2 pl-4 md:pl-0'
    classes += ` ${cell.column.className}`
    classes += row.depth !== 0 ? ' sub-row-cell' : ''
    classes += index !== 0 && !cell.value ? ' cell-no-data' : ''
    return classes
  }

  const getExpandedRowBorder = () => {
    return ' border-x-2 border-t-2 border-x-lime-500 border-t-lime-500 sub-row-parent'
  }

  const getSubRowBorder = (row) => {
    return row.index === 0
      ? ' border-x-2 border-b-2 border-x-lime-500 border-b-lime-500 sub-row'
      : ' border-x-2 border-x-lime-500 sub-row'
  }

  const getDataHeader = (row, cell) => {
    if (row.depth > 0) {
      return cell.column.AltHeader
        ? `Subtrade of ${row.original.parent}`
        : cell.column.Header
    } else {
      return cell.column.Header || cell.column.AltHeader
    }
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // initial sort
        sortBy: [
          {
            id: 'Name',
            desc: false
          }
        ],
        pageIndex: 0,
        pageSize: 10
      }
    },
    useSortBy,
    useExpanded,
    usePagination
  )

  const handleDownload = () => {
    const currentRecords = data
    const toDownload = []

    const getNestedValue = (obj, path) => {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj)
    }

    for (let index = 0; index < currentRecords.length; index++) {
      const dataRow = {}
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        const dataPoint = columns[colIndex].accessor
        if (dataPoint) {
          dataRow[columns[colIndex].Header] = getNestedValue(
            currentRecords[index],
            dataPoint
          )
        }
      }
      toDownload.push(dataRow)
    }
    setDownloadData(toDownload)
  }

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  if (loading) {
    return (
      <h1 className="flex items-center">
        Loading...{' '}
        <FontAwesomeIcon
          icon={faSpinner}
          className="h-4 w-4 ml-2 animate-spin"
        />
      </h1>
    )
  }
  if (!users) {
    return <h1>Error loading data</h1>
  }

  if (error) {
    return (
      <div>
        failed to load <code>{JSON.stringify(error, null, 4)}</code>
      </div>
    )
  }

  return (
    <div className="container mx-auto">
      <button onClick={handleDownload} className="button-confirm mb-2">
        Prep Download
      </button>
      {downloadData.length > 0 && (
        <CSVLink className="button-fill" data={downloadData}>
          Download
        </CSVLink>
      )}
      <table
        className="tradeTable table-auto w-full bg-white dark:bg-neutral-900 text-black dark:text-neutral-100 drop-shadow-lg"
        {...getTableProps()}
      >
        <thead className="bg-neutral-300 dark:bg-neutral-700 text-left">
          {headerGroups.map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="block md:table-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName
                    }
                  ])}
                >
                  <div className="md:flex md:items-center">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                key={row.id}
                {...row.getRowProps()}
                className={getRowClasses(row)}
              >
                {row.cells.map((cell, index) => (
                  <td
                    key={cell.id}
                    {...cell.getCellProps([
                      {
                        className: getCellClasses(row, cell, index),
                        style: cell.column.style,
                        'data-header': getDataHeader(row, cell)
                      }
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}

          {/* pagination controls */}
          <tr style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
            <td className="md:w-48">
              <button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </button>
              <button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {'<'}
              </button>
            </td>
            <td className="md:w-48 mt-2">
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </td>
            <td className="md:w-48">
              <input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
                className="input"
              />
            </td>
            <td className="md:w-48">
              <select
                value={pageSize}
                onChange={onChangeInSelect}
                className="input"
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </td>
            <td className="md:w-48">
              <button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {'>'}
              </button>
              <button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default UserDump
