import moment from 'moment'
import PropTypes from 'prop-types'
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CHANGE_CHANNEL } from '../../../constants/actions/messageListActions'
import { SEND } from '../../../constants/chatForms'
import { MODERATORS, TRADES } from '../../../constants/chatRooms'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import useIsMobileBrowser from '../../../hooks/useIsMobileBrowser'
import { usePrivateMessages } from '../../../hooks/usePrivateMessages'
import { usePublicMessages } from '../../../hooks/usePublicMessages'
import { useTradeService } from '../../../hooks/useTradeService'
import {
  initialState,
  messageListReducer
} from '../../../reducers/messageListReducer'
import ChatForm from '../../Forms/ChatForm'
import JoinTheConvo from '../../Forms/JoinTheConvo'
import Message from '../Message'
import MessageWrapper from '../MessageWrapper'
import PrivateMessage from '../PrivateMessage'
import Trade from '../Trade'
import LoadedAll from './LoadedAll'
import LoadingMore from './LoadingMore'

const MessageList = ({
  channel,
  modView,
  userId,
  showShadowBanned,
  search
}) => {
  const { user } = useContext(AuthenticationContext)
  const { freeEnabled } = useContext(SiteConfigContext)
  const [state, dispatch] = useReducer(messageListReducer, initialState)
  const [expandedUserInfoWidget, setExpandedUserInfoWidget] = useState(null)
  const scrollRef = useRef(0)
  const [keyModifier, setKeyModifier] = useState(0)
  const isMobileBrowser = useIsMobileBrowser()
  const { handleGetPrivateMessages } = usePrivateMessages(
    user,
    userId,
    channel,
    dispatch,
    modView
  )

  const { handleGetTrades } = useTradeService({
    dispatch,
    channel
  })

  const { getPublicMessages } = usePublicMessages({
    dispatch,
    channel,
    scrollRef,
    isMobileBrowser,
    setKeyModifier,
    modView,
    showShadowBanned,
    user
  })

  useEffect(() => {
    dispatch({ type: CHANGE_CHANNEL, payload: channel })
    getMessages(true)
  }, [channel, search])

  const getMessages = async (initialLoad = false) => {
    if (channel === TRADES) {
      handleGetTrades({ initialLoad, messages: state.messages, search })
    } else if (channel === MODERATORS) {
      handleGetPrivateMessages({
        initialLoad,
        messages: state.messages,
        userId: userId,
        search
      })
    } else {
      getPublicMessages({
        initialLoad,
        messages: state.messages,
        search
      })
    }
  }

  const handleScroll = (e) => {
    // on chrome you can scroll down to positive numbers and it breaks loading more
    if (e.target.scrollTop > 0) {
      e.target.scrollTop = 0
    }
    scrollRef.current = e.target.scrollTop
  }

  const popMessage = (message) => {
    switch (channel) {
      case MODERATORS:
        return <PrivateMessage modView={modView} message={message} />
      case TRADES:
        return <Trade message={message} truncate={true} />
      default:
        return (
          <Message
            message={message}
            channel={channel}
            expandedUserInfoWidget={expandedUserInfoWidget}
            setExpandedUserInfoWidget={setExpandedUserInfoWidget}
            modView={modView}
          />
        )
    }
  }

  const HandleDateSplitter = ({ state, message, index }) => {
    if (index !== 0) {
      const messageDate =
        message.postdate || message.createdAt || message.postDate || null

      if (messageDate) {
        const previousDate =
          state.messages[index - 1].postdate ||
          state.messages[index - 1].createdAt ||
          state.messages[index - 1].postDate ||
          null

        if (previousDate && moment(messageDate).isBefore(previousDate, 'day')) {
          return <DateSplitter date={previousDate} />
        }
      }
    }
    // If no DateSplitter is needed
    return null
  }

  return (
    <>
      <div
        id="messageList"
        className={`flex flex-col-reverse grow overflow-y-scroll md:border bg-white dark:bg-black border-neutral-200 dark:border-neutral-900 md:rounded-xl shadow-md pb-2 ${
          !modView ? 'scrollbar-hide md:w-96' : ''
        } ${channel === TRADES ? 'md:mb-2' : ''}`}
        key={`parent-div-${keyModifier}`}
      >
        <InfiniteScroll
          onScroll={handleScroll}
          dataLength={state.messages.length}
          next={getMessages}
          className="flex flex-col-reverse overflow-y-scroll gap-1.5"
          inverse
          hasMore={state.hasMore && state.channel === channel}
          scrollableTarget="messageList"
          loader={<LoadingMore />}
          endMessage={<LoadedAll channel={channel} />}
        >
          {state.channel === channel &&
            state.messages.map((message, index) => {
              return (
                <div key={message._id}>
                  <MessageWrapper
                    index={state.messages.length - index}
                    message={message}
                    channel={channel}
                    modView={modView}
                    dispatch={dispatch}
                  >
                    {popMessage(message)}
                  </MessageWrapper>
                  {HandleDateSplitter({
                    state,
                    message,
                    index
                  })}
                </div>
              )
            })}
        </InfiniteScroll>
      </div>
      {channel !== TRADES && (
        <div className="sticky bottom-0 shrink md:mb-2">
          {user?.onboarding.terms && user?.userService.profile.name && (
            <ChatForm
              channel={channel}
              type={SEND}
              dispatch={dispatch}
              toUser={userId}
            />
          )}
          {freeEnabled &&
            (!user ||
              !user.onboarding.terms ||
              !user.userService.profile.name) && <JoinTheConvo />}
        </div>
      )}
    </>
  )
}

const DateSplitter = ({ date }) => {
  const dateSplitterRuleClass =
    'grow relative border-b border-neutral-100 dark:border-neutral-950 -top-[13px]'
  return (
    <div className="flex pt-2 w-full animate-pop-in">
      <div className={dateSplitterRuleClass} />
      <div className="relative w-fit mx-auto rounded-full px-4 py-0.5 bg-neutral-100 dark:bg-neutral-900">
        <span className="text-neutral-600 dark:text-neutral-400 text-sm">
          {moment(date).format('MMMM D')}
        </span>
      </div>
      <div className={dateSplitterRuleClass} />
    </div>
  )
}

DateSplitter.propTypes = {
  date: PropTypes.any
}

export default MessageList
