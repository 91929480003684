import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  deletePoll,
  getPoll,
  getPolls,
  postPoll,
  putPoll
} from '../../../utils/APIs/polls'
import PropTypes from 'prop-types'
import { LIVESTREAM_EVENTS } from '@moal/api'
import { SocketContext } from '../../../context/socketContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import Poll from '../../Utilities/Poll'
import { toast } from 'react-toastify'
import { CSVLink } from 'react-csv'
import { setPageTitle } from '../../../utils/utilities'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const Polls = () => {
  setPageTitle('Polls')
  const { socket } = useContext(SocketContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [polls, setPolls] = useState([])
  const { activePoll } = useContext(SiteConfigContext)
  const navigate = useNavigate()
  const [downloadData, setDownloadData] = useState({
    id: '',
    data: []
  })

  useEffect(() => {
    // function to get all polls
    fetchPolls()
  }, [])

  const fetchPolls = async () => {
    // set loading to true
    setLoading(true)
    try {
      // get all polls
      const response = await getPolls()
      // if response is successful and data is returned
      if (response && response.status === 200) {
        setPolls(response.data)
        setLoading(false)
      }
    } catch (error) {
      setError(error)
      setLoading(false)
      toast.error('Error fetching polls.')
    }
  }

  useEffect(() => {
    if (socket) {
      socket.on(LIVESTREAM_EVENTS.UPDATE_POLL, handleUpdatePoll)
    }
    return () => {
      if (socket) {
        socket.off(LIVESTREAM_EVENTS.UPDATE_POLL, handleUpdatePoll)
      }
    }
  }, [socket, polls])

  const handleUpdatePoll = (data) => {
    const { status, poll: pollData } = data
    switch (status) {
      case 'POST': {
        const updatedPolls = [pollData, ...polls]
        setPolls(updatedPolls)
        break
      }
      case 'LIVE': {
        fetchPolls()
        break
      }
      case 'VOTE':
      case 'CLOSE':
      case 'ARCHIVE': {
        const updatedPolls = polls.map((poll) => {
          return pollData._id === poll._id ? pollData : poll
        })
        setPolls(updatedPolls)

        break
      }
      case 'DELETE': {
        const updatedPolls = polls.filter((poll) => {
          return poll._id !== pollData._id
        })
        setPolls(updatedPolls)
        break
      }
      default:
        break
    }
    toast.success('Polls updated.')
  }

  const handleExport = async ({ id }) => {
    const response = await getPoll(id)
    if (response && response.status === 200) {
      const choices = response.data.choices
      const votes = response.data.votes
      const clean = []
      votes.forEach((vt) => {
        const data = {
          'User ID': vt.user._id,
          'Customer Number': vt.user.userService.customerNumber,
          Email: vt.user.userService.email,
          Name: vt.user.userService.profile.name,
          Vote: choices.find((chc) => chc._id == vt.option)?.option || 'ERROR'
        }
        clean.push(data)
      })

      setDownloadData({
        id: id,
        data: clean
      })
    }
  }

  const PollList = () => {
    return (
      <>
        <h2>Existing Polls</h2>
        <div className="w-full text-sm text-left text-neutral-500 dark:text-neutral-400">
          <div className="text-xs text-neutral-700 uppercase bg-neutral-100 dark:bg-neutral-700 dark:text-neutral-400">
            <div className="hidden md:flex justify-between">
              <div className="px-6 py-3">Prompt</div>
              <div className="px-6 py-3">Status</div>
              {/* <div className='px-6 py-3'>End Time</div> */}
              <div className="px-6 py-3">Actions</div>
            </div>
          </div>
          {polls.map((poll) => (
            <div
              key={poll._id}
              className="flex flex-wrap md:flex-nowrap justify-start bg-white border-b dark:bg-neutral-800 dark:border-neutral-700"
            >
              <div className="basis-full md:basis-2/5 p-2 md:px-6 md:py-3">
                <h3>{poll.prompt}</h3>
              </div>
              <div className="flex items-center basis-1/3 md:basis-1/5 p-2 md:px-6 md:py-3">
                {poll.isOpen && !poll.isArchived
                  ? 'Open'
                  : !poll.isOpen && !poll.isArchived && poll.queuePoll
                    ? 'Queued'
                    : 'Closed'}
              </div>
              {/* <div className='basis-full md:basis-1/5 p-2 md:px-6 md:py-3'><p className='md:hidden'>End Time</p>{new Date(poll.endTime).toLocaleString()}</div> */}
              <div className="flex gap-2 items-center basis-full md:basis-2/5 p-2 md:px-6 md:py-3 justify-start md:justify-end h-auto">
                {!poll.isOpen && !poll.isArchived && poll.queuePoll && (
                  <button
                    className="neutral-button"
                    onClick={async () =>
                      await putPoll(poll._id, true, false, poll.timeOffset)
                    }
                  >
                    Go Live
                  </button>
                )}
                <button
                  className="neutral-button"
                  onClick={() => navigate(`/mod/polls/${poll._id}`)}
                >
                  View
                </button>
                {downloadData.id !== poll._id && (
                  <button
                    className="neutral-button"
                    onClick={() => handleExport({ id: poll._id })}
                  >
                    {' '}
                    Export
                  </button>
                )}
                {downloadData.id === poll._id && (
                  <CSVLink
                    filename={`${poll.prompt
                      .replace(/[^a-zA-Z ]/g, '')
                      .replace(/ /g, '-')}.csv`}
                    className="primary-button"
                    data={downloadData.data}
                  >
                    Download
                  </CSVLink>
                )}
                <button
                  className="cancel-button"
                  onClick={async () => await deletePoll(poll._id)}
                >
                  {' '}
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row gap-2 shrink">
        <div className="w-full max-w-full flex flex-col gap-2">
          <h2>Create New Poll</h2>
          <AddPollForm polls={polls} setPolls={setPolls} />
        </div>
        {activePoll && (
          <div className="flex flex-col gap-2 min-w-96 max-w-full p-2">
            <h2>Active Poll</h2>
            <Poll {...activePoll} />
            <button
              className="w-fit button-cancel mr-4"
              onClick={async () => putPoll(activePoll._id, false, true)}
            >
              Archive
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2 shrink">
        {loading ? <PollList /> : error ? <h2>Error</h2> : <PollList />}
      </div>
    </div>
  )
}

const AddPollForm = () => {
  const [choices, setChoices] = useState(['', ''])
  const [selectedDuration, setSelectedDuration] = useState('5-minutes')

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    // get values from form and emit to socket
    const formData = new FormData(e.target)
    const prompt = formData.get('prompt')
    const choices = []
    formData.forEach((value, key) => {
      if (key.includes('choice')) {
        choices.push({ option: value })
      }
    })
    const timeOffset = formData.get('poll-duration')
    const isOpen = formData.get('queuePoll') === 'on' ? false : true
    const queuePoll = formData.get('queuePoll') === 'on' ? true : false

    // make sure prompt and choices are not empty
    if (!prompt || !choices || !timeOffset) {
      toast.error('Please provide all values...')
      return
    }
    const response = await postPoll(
      prompt,
      choices,
      timeOffset,
      isOpen,
      queuePoll
    )
    if (response && response.status === 201) {
      e.target.reset()
      setChoices(['', ''])
      toast.success('Poll created.')
    }
  }

  return (
    <div className="">
      <form
        aria-label="Add poll"
        onSubmit={handleOnSubmit}
        autoComplete="off"
        className="relative flex flex-col flex-grow items-start focus-within:z-10 gap-2 w-full"
      >
        <label className="flex flex-col gap-1 w-full">
          <h4>Prompt</h4>
          <textarea
            aria-label="Input poll prompt"
            id="promt"
            name="prompt"
            className="grow input w-full"
            placeholder="Enter a prompt..."
          />
        </label>
        <label className="flex flex-col gap-1 w-full">
          <h4>Choices</h4>
          <div className="flex flex-col gap-2 border-l-2 border-blue-600">
            {choices.map((choice, index) => (
              <div className="flex items-center pl-4" key={index}>
                <input
                  type="text"
                  aria-label={`Input poll choice ${index}`}
                  id={`choice-${index}`}
                  name={`choice-${index}`}
                  value={choice}
                  onChange={(e) =>
                    setChoices((oldChoices) => {
                      const newChoices = [...oldChoices]
                      newChoices[index] = e.target.value
                      return newChoices
                    })
                  }
                  className="grow input w-full"
                  placeholder="Enter a choice..."
                />
                {/* ADD BUTTON */}
                {/* REMOVE BUTTON */}
                {choices.length > 2 && (
                  <button
                    className="rounded-full bg-red-500 dark:bg-red-700 text-white px-2 py-1 ml-2"
                    type="button"
                    onClick={() =>
                      setChoices((oldChoices) => {
                        const newChoices = [...oldChoices]
                        newChoices.splice(index, 1)
                        return newChoices
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                )}
              </div>
            ))}
            <button
              className="rounded w-fit bg-blue-500 dark:bg-blue-700 text-white px-2.5 py-1 ml-4"
              type="button"
              onClick={() =>
                setChoices((oldChoices) => {
                  const newChoices = [...oldChoices, '']
                  return newChoices
                })
              }
            >
              <FontAwesomeIcon icon={faAdd} />
            </button>
          </div>
        </label>
        <div className="h-fit flex flex-col justify-between items-start">
          <h4>Duration</h4>
          <label className="inline-flex items-center mb-2" htmlFor="5-minutes">
            <input
              type="radio"
              id="5-minutes"
              name="poll-duration"
              className="form-radio"
              value="5-minutes"
              checked={selectedDuration === '5-minutes'}
              onChange={(e) => setSelectedDuration(e.target.value)}
            />
            <span className="ml-2">
              <p>5 minutes</p>
            </span>
          </label>
          <label className="inline-flex items-center mb-2" htmlFor="15-minutes">
            <input
              type="radio"
              id="15-minutes"
              name="poll-duration"
              className="form-radio"
              value="15-minutes"
              checked={selectedDuration === '15-minutes'}
              onChange={(e) => setSelectedDuration(e.target.value)}
            />
            <span className="ml-2">
              <p>15 minutes</p>
            </span>
          </label>
          <label className="inline-flex items-center mb-2" htmlFor="30-minutes">
            <input
              type="radio"
              id="30-minutes"
              name="poll-duration"
              className="form-radio"
              value="30-minutes"
              checked={selectedDuration === '30-minutes'}
              onChange={(e) => setSelectedDuration(e.target.value)}
            />
            <span className="ml-2">
              <p>30 minutes</p>
            </span>
          </label>
        </div>
        <div className="h-fit flex flex-col justify-between items-start">
          <h4>Queue Poll?</h4>
          <div className="inline-flex items-center mb-2">
            <input type="checkbox" id="queuePoll" name="queuePoll" />
            <label htmlFor="queuePoll" className="ml-2">
              <p>Queue</p>
            </label>
          </div>
        </div>
        <button
          type="submit"
          aria-label="Submit poll"
          className="button-confirm"
        >
          Submit Poll
        </button>
      </form>
    </div>
  )
}

AddPollForm.propTypes = {
  setPolls: PropTypes.func.isRequired,
  socket: PropTypes.any
}

export default Polls
