import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, useLocation } from 'react-router-dom'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'

const ContentNav = ({ navOptions }) => {
  const { [SETTINGS.FEATURES.key]: enabledFeatures } =
    useContext(SiteConfigContext)

  const [activeTab, setActiveTab] = useState(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const location = useLocation()

  useEffect(() => {
    const currentTab = navOptions.find(
      (tab) => tab.href === location.pathname.split('/').pop()
    )
    setActiveTab(currentTab)
  }, [location])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    setIsDropdownOpen(false)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <div className="mb-4">
      <div className="md:hidden">
        <DropdownNav
          activeTab={activeTab}
          tabs={navOptions}
          isDropdownOpen={isDropdownOpen}
          onTabClick={handleTabClick}
          toggleDropdown={toggleDropdown}
        />
      </div>
      <div className="hidden md:block">
        <nav
          className="isolate flex divide-x divide-neutral-200 dark:divide-neutral-700 rounded shadow"
          aria-label="Tabs"
        >
          {navOptions.map((tab) => {
            if (
              !tab.feature ||
              (tab.feature && enabledFeatures && enabledFeatures[tab.feature])
            ) {
              return (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  className={`${
                    activeTab === tab
                      ? 'text-brand-primary-600 dark:text-brand-primary-500'
                      : 'text-neutral-700 hover:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-50'
                  } ${navOptions.indexOf(tab) === 0 ? 'rounded-l' : ''} ${
                    navOptions.indexOf(tab) === navOptions.length - 1
                      ? 'rounded-r'
                      : ''
                  } group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-black py-4 px-4 text-center text-sm font-medium hover:bg-neutral-200/50 hover:dark:bg-neutral-800 focus:z-10 flex items-center justify-center`}
                  onClick={() => handleTabClick(tab)}
                  aria-current={activeTab === tab ? 'page' : undefined}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      activeTab === tab
                        ? 'bg-brand-primary-600 dark:bg-brand-primary-400'
                        : 'bg-transparent'
                    } absolute inset-x-0 bottom-0 h-1`}
                  />
                </NavLink>
              )
            }
          })}
        </nav>
      </div>
    </div>
  )
}

const DropdownNav = ({
  activeTab,
  tabs,
  isDropdownOpen,
  onTabClick,
  toggleDropdown
}) => {
  const handleTabClick = (tab) => {
    onTabClick(tab)
  }

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        className="inline-flex items-center justify-between w-48 px-4 py-2 text-sm font-medium text-neutral-700 dark:text-neutral-100 bg-white dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 rounded hover:bg-neutral-50 hover:dark:bg-neutral-700 focus:border-brand-primary-500"
        id="dropdown-menu-button"
        aria-expanded={isDropdownOpen ? 'true' : 'false'}
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        {activeTab ? activeTab.name : ''}
        <FontAwesomeIcon
          icon={faChevronDown}
          className="h-3 w-3"
          aria-hidden="true"
        />
      </button>

      {isDropdownOpen && (
        <div
          className="origin-top-right absolute z-50 left-0 w-fit mt-2 rounded shadow bg-white dark:bg-neutral-800 transition-all duration-200"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-menu-button"
        >
          <div className="py-1" role="none">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={`${
                  activeTab === tab
                    ? 'text-brand-primary-700 dark:text-brand-primary-500 border-l-2 border-brand-primary-700 dark:border-brand-primary-500 bg-brand-primary-100/75 dark:bg-brand-primary-900/25'
                    : 'text-neutral-700 dark:text-neutral-100'
                } block px-4 py-2 text-sm font-medium hover:bg-neutral-200/50 hover:dark:bg-neutral-700/50`}
                role="menuitem"
                onClick={() => handleTabClick(tab)}
              >
                {tab.name}
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

DropdownNav.propTypes = {
  activeTab: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  ),
  isDropdownOpen: PropTypes.bool.isRequired,
  onTabClick: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired
}

export default ContentNav
