import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import Header from '../../components/Layout/Header'
import Nav from '../../components/Moderation/Nav'
import { setPageTitle } from '../../utils/utilities'

const Moderation = () => {
  setPageTitle('Moderation')
  const { user } = useContext(AuthenticationContext)

  if (!user.userService.profile.name) {
    // will replace with mod check
    return <Navigate to="/" />
  }

  return (
    <main className="set-vh flex flex-col h-screen max-h-screen lg:overflow-hidden">
      <Header />
      <Nav />
      <div className="flex flex-1 bg-neutral-100 dark:bg-neutral-900 overflow-y-auto h-full">
        <div className="flex flex-1 flex-col w-full container h-full">
          <section className="h-full p-2.5 lg:p-5 lg:pl-72">
            <Outlet />
          </section>
        </div>
      </div>
    </main>
  )
}

export default Moderation
