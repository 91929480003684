import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

const LoadingScreen = ({ showSpinner = false }) => {
  return (
    <main className="flex h-screen max-h-screen flex-col overflow-hidden transition-all ease-in-out duration-300">
      {showSpinner && (
        <div className="m-auto text-white">
          <FontAwesomeIcon icon={faSpinner} size="6x" spin />
        </div>
      )}
    </main>
  )
}

LoadingScreen.propTypes = {
  showSpinner: PropTypes.bool
}

export default LoadingScreen
