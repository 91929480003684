import { PRODUCT_TYPES } from '@moal/api'
import React, { useContext, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { setPageTitle } from '../../../utils/utilities'
import ToggleSwitch from '../../Forms/common/ToggleSwitch'
import AdBanner from '../../Utilities/AdBanner'

const MAX_BODY_LENGTH = 500

const AdBuilder = () => {
  setPageTitle('Ad Builder')
  return (
    <div className="flex-col h-full w-full gap-2">
      <div className="w-96 max-w-full flex flex-col gap-2 mb-4">
        <ToggleBanner />
        <BuilderForm />
      </div>
      <div className="w-96 md:w-full mb-4">
        <h2>Preview</h2>
        <div className="mx-auto w-full p-2 lg:p-0">
          <AdBanner />
        </div>
      </div>
    </div>
  )
}

const ToggleBanner = () => {
  const { [SETTINGS.DISPLAY_BANNER.key]: displayBanner } =
    useContext(SiteConfigContext)

  return (
    <div className="flex gap-2">
      <ToggleSwitch
        setting={SETTINGS.DISPLAY_BANNER}
        value={displayBanner}
        label="Display Banner"
        color="green"
      />
    </div>
  )
}

const BuilderForm = () => {
  const {
    productsService,
    [SETTINGS.BANNER_DATA.key]: bannerData,
    updateSetting
  } = useContext(SiteConfigContext)

  const [formState, setFormState] = useState(SETTINGS.BANNER_DATA.default)

  useEffect(() => {
    if (bannerData) {
      setFormState((formState) => ({ ...formState, ...bannerData }))
    }
  }, [bannerData])

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateSetting(SETTINGS.BANNER_DATA, formState)
    toast.success('Banner updated.')
  }

  const handleChange = (field, value) => {
    setFormState((prevState) => ({ ...prevState, [field]: value }))
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="flex flex-col gap-2">
        {productsService[PRODUCT_TYPES.PRODUCT].details?.openHouse?.enabled && (
          <div className="flex items-center">
            <input
              checked={formState.openHouseOnly}
              onChange={() =>
                handleChange('openHouseOnly', !formState.openHouseOnly)
              }
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Show to Open House Only
            </label>
          </div>
        )}
        <div className="flex items-center">
          <input
            checked={formState.showCountdown}
            onChange={() =>
              handleChange('showCountdown', !formState.showCountdown)
            }
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Show Countdown
          </label>
        </div>
        {formState.showCountdown && (
          <div className="flex flex-col pl-3 border-l-2 border-blue-500 mt-1">
            <div className="mb-1">
              <label className="dark:text-neutral-100">End Date & Time</label>
              <input
                type="datetime-local"
                value={formState.endDateTime}
                onChange={(e) => handleChange('endDateTime', e.target.value)}
                className="grow input w-full"
              />
            </div>
            <div className="mb-1" />
            <label className="dark:text-neutral-100">Countdown Text</label>
            <input
              value={formState.countdownText}
              onChange={(e) => handleChange('countdownText', e.target.value)}
              className="input block w-full"
              placeholder="Countdown Text"
            />
          </div>
        )}
        <label className="dark:text-neutral-100">Promo Link</label>
        <input
          value={formState.promoLink}
          onChange={(e) => handleChange('promoLink', e.target.value)}
          className="input block w-full"
          placeholder="Promo Link"
        />
        <label className="dark:text-neutral-100">
          Body
          {formState.body.length >=
            MAX_BODY_LENGTH - MAX_BODY_LENGTH * 0.25 && (
            <span className="ml-2 text-xs">
              <span
                className={
                  formState.body.length === MAX_BODY_LENGTH
                    ? 'text-red-500'
                    : ''
                }
              >
                ({formState.body.length} / {MAX_BODY_LENGTH})
              </span>
            </span>
          )}
        </label>
        <TextareaAutosize
          aria-label="Input bot message"
          id="input"
          value={formState.body}
          onChange={(e) => handleChange('body', e.target.value)}
          className="input block w-full flex-auto h-[2.6rem] min-h-[2.6rem] "
          placeholder="Enter a message..."
          minRows={1}
          maxRows={4}
          autoComplete="off"
          maxLength={MAX_BODY_LENGTH}
        />
        <div className="flex items-center">
          <input
            checked={formState.showButton}
            onChange={() => handleChange('showButton', !formState.showButton)}
            type="checkbox"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Show Button
          </label>
        </div>
        {formState.showButton && (
          <div className="flex flex-col pl-3 border-l-2 border-blue-500 mt-1">
            <div className="mb-1" />
            <label className="dark:text-neutral-100">Call to Action Text</label>
            <input
              value={formState.buttonText}
              onChange={(e) => handleChange('buttonText', e.target.value)}
              className="input block w-full"
              placeholder="Call to Action"
            />
          </div>
        )}

        <div className="flex items-center">
          <div className="flex flex-col w-1/2 mr-2">
            <label className="dark:text-neutral-100">Background Color</label>
            <select
              value={formState.bgColor}
              onChange={(e) => handleChange('bgColor', e.target.value)}
              className="input block w-full"
            >
              <option value="red">Red</option>
              <option value="blue">Blue</option>
              <option value="green">Green</option>
              <option value="yellow">Yellow</option>
              <option value="purple">Purple</option>
            </select>
          </div>
          <div className="flex flex-col w-1/2">
            <label className="dark:text-neutral-100">Button Color</label>
            <select
              value={formState.buttonColor}
              onChange={(e) => handleChange('buttonColor', e.target.value)}
              className="input block w-full"
            >
              <option value="red">Red</option>
              <option value="blue">Blue</option>
              <option value="green">Green</option>
              <option value="yellow">Yellow</option>
              <option value="purple">Purple</option>
            </select>
          </div>
        </div>
        <button
          type="submit"
          aria-label="Submit Role"
          className="button-confirm w-fit"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default AdBuilder
