import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SignUpForm from '../SignUpForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

const JoinTheConvo = () => {
  const [isTurnstileOpen, setIsTurnstileOpen] = useState(true)
  const toggleTurnstile = () => {
    setIsTurnstileOpen(!isTurnstileOpen)
  }

  return (
    <>
      <div id="chat-form" className="relative flex items-stretch z-20 gap-2">
        {!isTurnstileOpen && (
          <div className="flex flex-col w-full">
            <button
              type="submit"
              aria-label="Submit message"
              className="button-fill relative rounded-r w-fit md:w-full text-center mx-auto m-2 md:m-0"
              onClick={toggleTurnstile}
            >
              Join the Conversation!
            </button>
          </div>
        )}
      </div>
      {isTurnstileOpen && (
        <div className="flex flex-col relative bg-brand-primary-100 dark:bg-brand-primary-900 border border-brand-primary-400 dark:border-brand-primary-800 md:rounded-xl md:w-96">
          <button className="button-x" onClick={toggleTurnstile}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="p-2">
            <SignUpForm />
          </div>
        </div>
      )}
    </>
  )
}

JoinTheConvo.propTypes = {
  channel: PropTypes.string
}

export default JoinTheConvo
