import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HeadquartersButton = ({ title, link, content, icon }) => {
  return (
    <div className="w-full col-span-3 lg:col-span-1">
      <div className="flex flex-col h-full p-4 gap-2 border shadow-md dark:border-neutral-900 rounded-xl bg-white dark:bg-black overflow-clip">
        <h3 className="flex items-center font-semibold gap-2">
          {title}
          {icon && <FontAwesomeIcon icon={icon} className="h-5 w-5" />}
        </h3>
        <p className="max-w-2xl text-sm font-medium">{content}</p>
        <button className="button-outline w-fit mt-auto">
          <Link to={link} target="_blank" rel="noopener noreferrer">
            {title}
          </Link>
        </button>
      </div>
    </div>
  )
}

HeadquartersButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.object
}

export default HeadquartersButton
