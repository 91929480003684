import React, { useContext } from 'react'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'

const TermsAndConditionsText = () => {
  const { [SETTINGS.TERMS_AND_CONDITIONS.key]: terms } =
    useContext(SiteConfigContext)

  return (
    <div
      className="flex flex-col bg-white dark:bg-neutral-950 p-5 h-96 overflow-x-auto"
      dangerouslySetInnerHTML={{ __html: terms }}
    />
  )
}

export default TermsAndConditionsText
