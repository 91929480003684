import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-moment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { getViewCounts } from '../../../../utils/APIs/miscellaneous'
import DateRangePicker from '../DateRangePicker'
import styles from '../Stats.module.css'
import { getDateRangeLabels } from '../utils'

ChartJS.register(
  TimeScale,
  TimeSeriesScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const ViewsPerHour = () => {
  const [views, setViews] = useState([])
  const [labels, setLabels] = useState([])
  const [data, setData] = useState(null)
  const [startDate, setStartDate] = useState(
    moment().subtract(2, 'days').toDate()
  )
  const [endDate, setEndDate] = useState(moment().toDate())
  const [scaleType, setScaleType] = useState('timeseries')

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.2 // smooth lines
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        titleFont: {
          size: 18
        },
        bodyFont: {
          size: 24
        },
        padding: 12
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          tooltipFormat: 'M/D hA',
          displayFormats: {
            hour: 'M/D hA'
          }
        },
        ticks: {
          stepSize: 1
        }
      },
      y: {
        ticks: {
          stepSize: 100
        }
      }
    }
  }

  useEffect(() => {
    loadViews()
    handleLabels()
  }, [startDate, endDate])

  useEffect(() => {
    handleData()
  }, [views])

  const loadViews = async () => {
    const response = await getViewCounts(
      'HOURLY',
      moment(startDate).toISOString(),
      moment(endDate).toISOString()
    )
    if (response && response.status === 200) {
      setViews(response.data)
    }
  }

  const handleLabels = () => {
    const dateLabels = getDateRangeLabels(startDate, endDate)
    setLabels(dateLabels)
  }

  const handleData = () => {
    setData({
      labels,
      datasets: [
        {
          label: 'Live',
          data: views.map((item) => {
            return {
              x: item._id,
              y: item.isLive ? Math.round(item.maxCount) : 0
            }
          }),
          borderColor: '#355070',
          backgroundColor: '#355070',
          pointStyle: false,
          fill: true
        },
        {
          label: 'Offline',
          data: views.map((item) => {
            return {
              x: item._id,
              y: item.isLive === false ? Math.round(item.maxCount) : 0
            }
          }),
          borderColor: '#9e2a2b',
          backgroundColor: '#9e2a2b',
          pointStyle: false,
          fill: true
        }
      ]
    })
  }

  if (labels.length <= 0 || data === null) {
    return (
      <div className={styles.wrapper}>
        <div className="flex items-center justify-center my-auto">
          <p className="animate-pulse">Loading...</p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <h2>Viewers Per Hour</h2>

      <Line options={options} data={data} className="p-2" />
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        toggleScaleType={false}
        scaleType={scaleType}
        setScaleType={setScaleType}
        rangeType="datetime-local"
      />
    </div>
  )
}

export default ViewsPerHour
