import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useState } from 'react'
import styles from './DateRangePicker.module.css'

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  rangeType = 'date',
  scaleType,
  setScaleType,
  toggleScaleType = true
}) => {
  const [startError, setStartError] = useState(false)
  const [endError, setEndError] = useState(false)

  let timeFormat
  switch (rangeType) {
    case 'date':
      timeFormat = 'YYYY-MM-DD'
      break
    case 'datetime-local':
      timeFormat = 'YYYY-MM-DDTHH:mm'
      break
    case 'time':
      timeFormat = 'HH:mm'
      break
    default:
      timeFormat = 'YYYY-MM-DD'
      break
  }

  const handleStartDate = (value) => {
    if (value.isBefore(moment(endDate), 'day')) {
      setStartDate(value.toDate())
      setStartError(false)
    } else {
      setStartError(true)
    }
  }

  const handleEndDate = (value) => {
    if (value.isAfter(moment(startDate), 'day')) {
      setEndDate(value.toDate())
      setEndError(false)
    } else {
      setEndError(true)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className="flex flex-col md:flex-row gap-2">
        <label className={styles.range}>
          <p>From</p>
          <input
            className={`border ${
              startError ? ' border-red-500' : 'dark:border-neutral-700'
            }`}
            type={rangeType}
            value={moment(startDate).format(timeFormat)}
            onChange={(e) =>
              handleStartDate(moment(e.target.value).startOf('day'))
            }
          />
        </label>
        <label className={styles.range}>
          <p>To</p>
          <input
            className={`border ${
              endError ? 'border-red-500' : 'dark:border-neutral-700'
            }`}
            type={rangeType}
            value={moment(endDate).format(timeFormat)}
            onChange={(e) => handleEndDate(moment(e.target.value).endOf('day'))}
          />
        </label>
      </div>
      {toggleScaleType && (
        <label
          className={`${styles.showAll} ${
            scaleType === 'timeseries'
              ? 'bg-green-500/15 hover:bg-green-500/25 border-green-500/50'
              : 'bg-red-500/15 hover:bg-red-500/25 border-red-500/50'
          }`}
        >
          <p className="select-none text-sm">All Days</p>
          <p className="sr-only">
            {scaleType === 'timeseries' ? `Show Empty Days` : `Hide Empty Days`}
          </p>
          <input
            type="checkbox"
            checked={scaleType === 'time'}
            onChange={(e) =>
              setScaleType(e.target.checked ? 'time' : 'timeseries')
            }
            className="hidden"
          />
          <FontAwesomeIcon
            icon={scaleType === 'timeseries' ? faEye : faEyeSlash}
            className={`${
              scaleType === 'timeseries' ? 'text-green-500' : 'text-red-500'
            }`}
          />
        </label>
      )}
    </div>
  )
}

export default DateRangePicker
