import moment from 'moment'

export const getDateRangeLabels = (start, end) => {
  const startMoment = moment(start).format('YYYY-MM-DD')
  const endMoment = moment(end).format('YYYY-MM-DD')
  const dateArray = []

  dateArray.push(startMoment)
  if (startMoment !== endMoment) {
    dateArray.push(endMoment)
  }
  return dateArray
}
