import { postTrade, putTrade } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { EDIT_MESSAGE } from '../../../constants/actions/messageListActions'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { PRODUCT_CODE } from '../../../utils/ENV'
import ReactQuillEditor from '../../Utilities/ReactQuillEditor'
import ToggleSwitchBasic from '../common/ToggleSwitchBasic'
import UploadImages from '../common/UploadImages'
import PostTradeFormPreview from '../PostTradeFormPreview'

const TRADE_FIELDS_MAX_LENGTH = 70

const defaultValues = {
  type: 'open',
  headline: '',
  body: '',
  option: '',
  images: [],
  itemNumber: PRODUCT_CODE,
  foundBySAM: false
}

const PostTradeForm = ({ edit, closeModal, prefill, dispatch }) => {
  const { user } = useContext(AuthenticationContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    state: false,
    message: ''
  })

  const [trade, setTrade] = useState(defaultValues)

  const [keyModifer, setKeyModifier] = useState(0)

  useEffect(() => {
    if (prefill) {
      const option = prefill?.extra?.find((obj) => {
        return obj.key === 'option'
      })
      const images = prefill?.extra?.find((obj) => {
        return obj.key === 'images'
      })
      const foundBySAM = prefill?.extra?.find((obj) => {
        return obj.key === 'foundBySAM'
      })

      setTrade({
        _id: prefill._id,
        type: prefill.type,
        headline: prefill.headline,
        body: prefill.body,
        option: option?.value || '',
        images: images?.value || [],
        itemNumber: PRODUCT_CODE,
        foundBySAM: foundBySAM?.value || false
      })
      setKeyModifier(keyModifer + 1)
    }
  }, [prefill])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    toast.success(edit ? 'Trade updated.' : 'Trade posted.')
    setError({
      state: false,
      message: ''
    })

    const data = { ...trade, body: sanitizeText(trade.body) }

    if (edit) {
      handlePutTrade({
        user,
        data
      })
      closeModal(false)
    } else {
      handlePostTrade({
        user,
        data
      })
    }

    setTrade(defaultValues)
    setKeyModifier(keyModifer + 1)
  }

  const handlePostTrade = async ({ user, data }) => {
    const response = await postTrade({ user, data })
    if (response.status === 201) {
      setLoading(false)
      setError({
        state: false,
        message: ''
      })
    }
  }

  const handlePutTrade = async ({ user, data }) => {
    const response = await putTrade({ user, data })
    if (response && response.status === 200) {
      if (dispatch) {
        dispatch({ type: EDIT_MESSAGE, payload: response.data })
      }
    }
  }

  const returnImages = (imgs) => {
    setTrade((trade) => ({ ...trade, images: imgs }))
  }

  const handleRemoveImages = (e) => {
    e.preventDefault()
    setTrade((trade) => ({ ...trade, images: [] }))
  }

  const handleOnChange = (value) => {
    setTrade((trade) => ({ ...trade, body: value }))
  }

  const sanitizeText = (text) => {
    const emptyParagraphsRegex = /<p><br><\/p>/g
    const nonBreakingSpaceRegex = /&nbsp;/g

    const scrubbedHTML = text
      .replace(emptyParagraphsRegex, '')
      .replace(nonBreakingSpaceRegex, ' ')

    return scrubbedHTML
  }

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-2 w-full gap-5 pb-5">
      <div className="lg:col-span-1">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="flex flex-col gap-5">
            <h2>Post a New Trade</h2>
            <div className="flex flex-row justify-start">
              <div className="flex items-center">
                <label className="mr-2 dark:text-neutral-100">Post Type</label>
                <select
                  value={trade.type}
                  onChange={(e) =>
                    setTrade((trade) => ({ ...trade, type: e.target.value }))
                  }
                  className="shrink rounded border border-neutral-300 p-2 dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-100"
                >
                  <option value="OPEN">Open</option>
                  <option value="UPDATE">Update</option>
                  <option value="CLOSED">Closed</option>
                </select>
              </div>
              <div className="mb-4 ms-4 flex items-center">
                <ToggleSwitchBasic
                  value={trade.foundBySAM}
                  label="Found By S.A.M."
                  color="green"
                  onChange={(value) =>
                    setTrade((trade) => ({ ...trade, foundBySAM: value }))
                  }
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">
                Headline
                {trade.headline.length >=
                  TRADE_FIELDS_MAX_LENGTH - TRADE_FIELDS_MAX_LENGTH * 0.25 && (
                  <span className="ml-2 text-xs">
                    <span
                      className={
                        trade.headline.length === TRADE_FIELDS_MAX_LENGTH
                          ? 'text-red-500'
                          : ''
                      }
                    >
                      ({trade.headline.length} / {TRADE_FIELDS_MAX_LENGTH})
                    </span>
                  </span>
                )}
              </label>
              <input
                value={trade.headline}
                onChange={(e) =>
                  setTrade((trade) => ({ ...trade, headline: e.target.value }))
                }
                className="input"
                required
                maxLength={TRADE_FIELDS_MAX_LENGTH}
              />
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">
                Ticker / Symbol / Option
                {trade.option.length >=
                  TRADE_FIELDS_MAX_LENGTH - TRADE_FIELDS_MAX_LENGTH * 0.25 && (
                  <span className="ml-2 text-xs">
                    <span
                      className={
                        trade.option.length === TRADE_FIELDS_MAX_LENGTH
                          ? 'text-red-500'
                          : ''
                      }
                    >
                      ({trade.option.length} / {TRADE_FIELDS_MAX_LENGTH})
                    </span>
                  </span>
                )}
              </label>
              <input
                value={trade.option}
                onChange={(e) =>
                  setTrade((trade) => ({
                    ...trade,
                    option: e.target.value
                  }))
                }
                className="input"
                required
                maxLength={TRADE_FIELDS_MAX_LENGTH}
              />
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Body</label>
              <ReactQuillEditor
                key={keyModifer}
                initialHtml={trade.body}
                onChange={handleOnChange}
              />
            </div>
            {!loading && (
              <div className="flex flex-col">
                <label className="dark:text-neutral-100">Images</label>
                <UploadImages returnImages={returnImages} />
                {edit && trade.images.length > 0 && (
                  <div className="mt-1">
                    <span className="text-red-500">
                      Adding new images will replace old images.
                    </span>
                    <button
                      className="mt-1 button-cancel"
                      onClick={(e) => handleRemoveImages(e)}
                    >
                      Remove All Images
                    </button>
                  </div>
                )}
              </div>
            )}{' '}
            {/* this isn't the best solution, but hiding the images form when it's submit resets it */}
            <div className="flex flex-col">
              {error.state && <p className="text-red-400">{error.message}</p>}
              <input
                type="submit"
                className="button-confirm"
                value={loading ? 'Submitting...' : 'Submit'}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="lg:col-span-1 flex flex-col gap-5">
        <PostTradeFormPreview
          trade={{ ...trade, body: sanitizeText(trade.body) }}
        />
      </div>
    </div>
  )
}

PostTradeForm.propTypes = {
  edit: PropTypes.bool,
  closeModal: PropTypes.func,
  prefill: PropTypes.object,
  dispatch: PropTypes.any
}

export default PostTradeForm
