import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply, faThumbTack } from '@fortawesome/pro-solid-svg-icons'
import { MEMBERS } from '../../../constants/chatRooms'

import * as SETTINGS from '../../../constants/settings'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const ActionPanel = ({ message, channel, handleReply }) => {
  const { updateSetting } = useContext(SiteConfigContext)
  const { user } = useContext(AuthenticationContext)

  const handlePin = async () => {
    await updateSetting(SETTINGS.PINNED_MESSAGE, message)
  }

  return (
    <div className="absolute right-1 top-0 flex gap-1 items-center p-[3px]">
      {user && channel === MEMBERS && (
        <button
          onClick={handleReply}
          className="button-outline flex items-center justify-center p-[3px] rounded-full bg-neutral-100 dark:bg-neutral-900"
        >
          <FontAwesomeIcon className="w-3 h-3" icon={faReply} />
        </button>
      )}
      {channel === MEMBERS && hasStaffPrivilege(user) && (
        <button
          onClick={handlePin}
          className="button-outline flex items-center justify-center p-[3px] rounded-full bg-neutral-100 dark:bg-neutral-900"
        >
          <FontAwesomeIcon className="-rotate-45 w-3 h-3" icon={faThumbTack} />
        </button>
      )}
    </div>
  )
}

ActionPanel.propTypes = {
  handleReply: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired
}

export default ActionPanel
