import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import UploadImages from '../../../Forms/common/UploadImages'
import CurrentStreamers from './CurrentStreamers'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'

const Streamers = () => {
  setPageTitle('Content | Streamers')
  const {
    isLoading,
    [SETTINGS.STREAMERS.key]: streamers,
    updateSetting
  } = useContext(SiteConfigContext)
  const [currentEdit, setCurrentEdit] = useState()

  const handleAddButton = async (data) => {
    if (currentEdit != null) {
      const copy = streamers
      copy.splice(currentEdit, 1, data)
      await updateSetting(SETTINGS.STREAMERS, copy)
      toast.success('Streamer updated.')
      setCurrentEdit(null)
    } else {
      await updateSetting(SETTINGS.STREAMERS, [...streamers, data])
      toast.success('Streamer created.')
    }
  }

  const handleEditButton = (index) => {
    setCurrentEdit(index)
  }

  const handleDeleteButton = (index) => {
    const copy = streamers
    copy.splice(index, 1)
    updateSetting(SETTINGS.STREAMERS, copy)
    toast.success('Streamer deleted.')
  }

  if (isLoading) {
    return (
      <h1 className="flex items-center">
        Loading...{' '}
        <FontAwesomeIcon
          icon={faSpinner}
          className="h-4 w-4 ml-2 animate-spin"
        />
      </h1>
    )
  }

  return (
    <div className="flex flex-col gap-5">
      <CurrentStreamers
        streamers={streamers}
        handleEdit={handleEditButton}
        handleDelete={handleDeleteButton}
        display={'grid'}
      />
      <AddStreamerForm
        handleAddButton={handleAddButton}
        prefill={streamers[currentEdit]}
      />
      <div>
        <button
          type="button"
          className="button-cancel w-fit mt-1"
          onClick={() => setCurrentEdit(undefined)}
        >
          Reset
        </button>
      </div>
    </div>
  )
}

const AddStreamerForm = ({ handleAddButton, prefill }) => {
  const [photo, setPhoto] = useState(null)
  const [name, setName] = useState('')
  const [IVSPlaybackURL, setIVSPlaybackURL] = useState('')

  useEffect(() => {
    setPhoto(prefill?.photo || null)
    setName(prefill?.name || '')
    setIVSPlaybackURL(prefill?.IVSPlaybackURL || '')
  }, [prefill])

  const handleSubmit = async (e) => {
    e.preventDefault()
    handleAddButton({
      photo,
      name,
      IVSPlaybackURL,
      active: prefill?.active === true || false
    })
    setPhoto(null)
    setName('')
    setIVSPlaybackURL('')
  }

  const updatePhoto = (image) => {
    setPhoto(image)
  }

  return (
    <div className="xl:p-5 flex flex-col gap-5 xl:bg-neutral-200/50 xl:dark:bg-neutral-800/50">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="w-full flex flex-col gap-5">
          <h2>Add a New Streamer</h2>
          <div className="flex flex-col">
            <label className="dark:text-neutral-100">Name</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="dark:text-neutral-100">IVS Playback URL</label>
            <input
              value={IVSPlaybackURL}
              onChange={(e) => setIVSPlaybackURL(e.target.value)}
              className="input"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="dark:text-neutral-100">Photo</label>
            <ImageSection
              title="Photo"
              image={photo}
              handleDelete={() => setPhoto(null)}
              returnImage={updatePhoto}
            />
          </div>
          <div className="flex flex-col">
            <input type="submit" className="button-fill w-fit" value="Submit" />
          </div>
        </div>
      </form>
    </div>
  )
}

AddStreamerForm.propTypes = {
  handleAddButton: PropTypes.func,
  prefill: PropTypes.object
}

const ImageSection = ({ image, handleDelete, returnImage }) => {
  return (
    <div className="col-span-1 flex flex-col gap-2">
      {image && (
        <div className="flex flex-col gap-2">
          <div className="mr-4 shrink-0">
            <img className="streamer-pic" src={image} alt="" />
          </div>
          <button
            onClick={handleDelete}
            className="w-fit text-xs button-cancel"
          >
            Remove
          </button>
        </div>
      )}
      <UploadImages returnImages={returnImage} single />
    </div>
  )
}

ImageSection.propTypes = {
  image: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  returnImage: PropTypes.func.isRequired
}

export default Streamers
