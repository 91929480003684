import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

const Accordion = ({ title, children, startOpen }) => {
  const [isOpen, setIsOpen] = useState(startOpen)

  return (
    <div className="max-h-[50%] overflow-y-auto rounded-lg border dark:border-neutral-800">
      <button
        className={`flex items-center w-full px-2 py-1 justify-between text-black dark:text-white bg-neutral-200 dark:bg-neutral-800 hover:bg-neutral-300 hover:dark:bg-neutral-700
          ${isOpen ? 'rounded-t-lg' : 'rounded-lg'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm font-semibold">{title}</span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faChevronRight}
          className="w-5 h-5"
        />
      </button>
      {isOpen && (
        <div
          className={`p-2 bg-white dark:bg-neutral-900 ${
            isOpen ? 'rounded-b-lg' : 'rounded-lg'
          }`}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Accordion
