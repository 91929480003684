import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({ progress }) => {
  return (
    <div className="flex w-[95%] justify-end px-2 rounded-full">
      <div className="h-2 w-full rounded-full bg-neutral-200 dark:bg-neutral-700">
        <div
          className="h-full rounded-full bg-neutral-500"
          style={{ width: progress + '%' }}
        />
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired
}

export default ProgressBar
