import {
  ANNOUNCEMENT_EVENTS,
  getLatestAnnouncement,
  markAnnouncementAsRead
} from '@moal/api'
import { useContext, useEffect, useState } from 'react'
import { AuthenticationContext } from '../../context/authenticationContext'
import { SocketContext } from '../../context/socketContext'
import { PRODUCT_CODE } from '../../utils/ENV'

const LatestAnnouncement = ({ children }) => {
  const { user } = useContext(AuthenticationContext)
  const { announcementsSocket: socket } = useContext(SocketContext)
  const [latestAnnouncement, setLatestAnnouncement] = useState(null)

  useEffect(() => {
    if (user) {
      loadLatestAnnouncement()
    }
  }, [user])

  useEffect(() => {
    if (socket) {
      socket.on(ANNOUNCEMENT_EVENTS.POST, addAnnouncement)
      socket.on(ANNOUNCEMENT_EVENTS.EDIT, editAnnouncement)
      socket.on(ANNOUNCEMENT_EVENTS.DELETE, deleteAnnouncement)
      socket.on(ANNOUNCEMENT_EVENTS.READ, loadLatestAnnouncement)
      socket.on(ANNOUNCEMENT_EVENTS.READ_ALL, handleAllRead)
    }

    return () => {
      if (socket) {
        socket.off(ANNOUNCEMENT_EVENTS.POST, addAnnouncement)
        socket.off(ANNOUNCEMENT_EVENTS.EDIT, editAnnouncement)
        socket.off(ANNOUNCEMENT_EVENTS.DELETE, deleteAnnouncement)
        socket.off(ANNOUNCEMENT_EVENTS.READ, loadLatestAnnouncement)
        socket.off(ANNOUNCEMENT_EVENTS.READ_ALL, handleAllRead)
      }
    }
  }, [socket, latestAnnouncement])

  const addAnnouncement = (data) => {
    if (
      user.userService.subscriptions.find(
        (sub) => sub.itemNumber.toUpperCase() === data.itemNumber
      )
    ) {
      setLatestAnnouncement(data)
    }
  }

  const editAnnouncement = (data) => {
    if (latestAnnouncement && latestAnnouncement._id === data._id) {
      setLatestAnnouncement(data)
    }
  }

  const deleteAnnouncement = (data) => {
    if (latestAnnouncement && latestAnnouncement._id === data._id) {
      loadLatestAnnouncement()
    }
  }

  const handleAllRead = () => {
    setLatestAnnouncement({ ...latestAnnouncement, read: new Date() })
  }

  const loadLatestAnnouncement = async () => {
    const response = await getLatestAnnouncement({
      token: localStorage.getItem('authToken'),
      itemNumber: PRODUCT_CODE
    })
    if (response.status === 200) {
      setLatestAnnouncement(response.data)
    }
  }

  const markLatestAnnouncementRead = async () => {
    if (!latestAnnouncement || latestAnnouncement.read) {
      return
    }
    const response = await markAnnouncementAsRead({
      token: localStorage.getItem('authToken'),
      id: latestAnnouncement._id
    })
    if (!response.status === 200) {
      console.error('Error marking announcement as read')
    }
  }

  return children({
    latestAnnouncement,
    markLatestAnnouncementRead
  })
}

export default LatestAnnouncement
