import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const PostDate = ({ date, source }) => {
  const [fulldate, setFulldate] = useState(false)
  const isPrivateMessage = source === 'privateMessage'

  return (
    <button
      onClick={() => setFulldate(!fulldate)}
      className={` cursor-pointer ${
        isPrivateMessage
          ? ''
          : ' relative bottom-0.5 inline-flex items-center text-[.7rem] leading-4 text-neutral-700 dark:text-neutral-300 mr-1.5 py-[.1rem] px-1 rounded bg-neutral-200/75 dark:bg-neutral-900'
      }`}
    >
      {fulldate
        ? moment(date).format('h:mm:ss a, MMM D YYYY')
        : moment(date).format('h:mm a')}
    </button>
  )
}

PostDate.propTypes = {
  date: PropTypes.string.isRequired
}

export default PostDate
