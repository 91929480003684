import React, { useContext } from 'react'
import Logo from '../../graphics/Logo'
import Footer from '../../components/Layout/Footer'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { useNavigate } from 'react-router-dom'
import styles from './AudioVideoTroubleshooting.module.css'
import { setPageTitle } from '../../utils/utilities'

const AudioVideoTroubleshooting = () => {
  setPageTitle('Audio & Video Troubleshooting')

  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <main className="flex flex-col h-screen overflow-y-auto xl:p-5 bg-primary-gradient">
      <div className="flex flex-col gap-5 lg:w-2/3 xl:w-1/2 justify-center mb-auto md:m-auto">
        <div className="container sm:rounded-lg bg-neutral-100 dark:bg-neutral-900 w-fit mx-auto p-5 shadow-lg">
          <div className="flex flex-col justify-between items-center gap-5">
            <div className="h-16 w-fit mx-auto my-5">
              <Logo />
            </div>
            <button className="button-outline" onClick={handleGoBack}>
              ← Go Back
            </button>
            <h1 className="text-center font-bold tracking-wide">
              Audio / Video Troubleshooting
            </h1>
            <div className={styles.wrapper}>
              <AudioHelpGuideText />
            </div>
            <button className="button-outline" onClick={handleGoBack}>
              ← Go Back
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </main>
  )
}

const AudioHelpGuideText = () => {
  const { [SETTINGS.AUDIO_HELP_GUIDE.key]: theGuide } =
    useContext(SiteConfigContext)

  return (
    <div
      className="flex flex-col gap-2"
      dangerouslySetInnerHTML={{ __html: theGuide }}
    />
  )
}

export default AudioVideoTroubleshooting
