import { getTracker } from '@moal/api'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { PRODUCT_CODE } from '../../../../utils/ENV'
import DateRangePicker from '../DateRangePicker'
import styles from '../Stats.module.css'

const UniqueUsersPerDayExport = () => {
  const [downloadData, setDownloadData] = useState([])
  const [fileName, setFileName] = useState('my-file.csv')

  const [scaleType, setScaleType] = useState('timeseries')

  const [startDate, setStartDate] = useState(
    moment().subtract(5, 'days').toDate()
  )
  const [endDate, setEndDate] = useState(moment().toDate())

  useEffect(() => {
    loadData()
  }, [startDate, endDate])

  const columns = useMemo(
    () => [
      {
        Header: 'User Service ID',
        accessor: '_id'
      },
      {
        Header: 'Customer Number',
        accessor: 'customerNumber'
      },
      {
        Header: 'Name',
        accessor: 'profile.name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Registration Date',
        accessor: 'registrationDate'
      },
      {
        Header: 'Current Subs',
        accessor: 'subscriptionsFlat'
      }
    ],
    []
  )

  const loadData = async () => {
    const response = await getTracker({
      token: localStorage.getItem('authToken'),
      itemNumber: PRODUCT_CODE,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString()
    })

    if (response && response.status === 200) {
      const start = moment(startDate).toISOString().split('T')[0]
      const end = moment(endDate).toISOString().split('T')[0]
      setFileName(`${PRODUCT_CODE}-${start}-${end}.csv`)
      const toDownload = []

      response.data.forEach((data) => {
        const currentRecords = data.users

        currentRecords.forEach((user) => {
          user.subscriptionsFlat = user.subscriptions
            .map((item) => `${item.itemNumber}=${item.subType || 'N/A'}`)
            .join(', ')
        })

        const getNestedValue = (obj, path) => {
          return path.split('.').reduce((acc, part) => acc && acc[part], obj)
        }

        for (let index = 0; index < currentRecords.length; index++) {
          const dataRow = {
            Date: data.createdAt.split('T')[0]
          }

          for (let colIndex = 0; colIndex < columns.length; colIndex++) {
            const dataPoint = columns[colIndex].accessor
            if (dataPoint) {
              dataRow[columns[colIndex].Header] = getNestedValue(
                currentRecords[index],
                dataPoint
              )
            }
          }

          toDownload.push(dataRow)
        }
      })
      setDownloadData(toDownload)
    }
  }

  if (downloadData === null) {
    return (
      <div className={styles.wrapper}>
        <div className="flex items-center justify-center my-auto">
          <p className="animate-pulse">Loading...</p>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <h2>Export Unique Sign Ins Per Day</h2>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        scaleType={scaleType}
        setScaleType={setScaleType}
      />
      {downloadData.length > 0 && (
        <CSVLink
          className="button-confirm mx-2 mb-2 w-fit"
          data={downloadData}
          filename={fileName}
        >
          Download
        </CSVLink>
      )}
    </div>
  )
}

export default UniqueUsersPerDayExport
