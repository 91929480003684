import { faThumbTack, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import Message from '../../Messages/Message'

const PinnedMessage = ({ message, unPin, toggle }) => {
  const { user } = useContext(AuthenticationContext)

  return (
    <div className="w-full pb-1 relative flex flex-col text-sm bg-amber-100 dark:bg-amber-950 md:rounded-xl shadow-md md:border border-amber-300 dark:border-amber-800">
      <div className="mb-1 flex items-center justify-between px-1">
        {hasStaffPrivilege(user) && (
          <button onClick={() => unPin()} className="flex items-center">
            <div className="flex items-center gap-1 py-0.5 px-1">
              <FontAwesomeIcon
                className="mt-1 mx-1 h-3.5 transition-all hover:text-red-600 active:text-red-800 text-amber-600 dark:text-amber-500 -rotate-45"
                icon={faThumbTack}
              />
              <p className="text-xs italic text-amber-600 dark:text-amber-500 mt-1 md:mt-0">
                Pinned Message
              </p>
            </div>
          </button>
        )}
        {!hasStaffPrivilege(user) && (
          <div className="flex items-center gap-1 py-0.5 px-1">
            <FontAwesomeIcon
              className="mt-1 mx-1 h-3.5 transition-all text-amber-600 dark:text-amber-500 -rotate-45"
              icon={faThumbTack}
            />
            <p className="text-xs italic text-amber-600 dark:text-amber-500 mt-1 md:mt-0">
              Pinned Message
            </p>
          </div>
        )}
      </div>
      <Message message={message} emphasize={false} />
      <button onClick={() => toggle(false)} className="button-x">
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  )
}

PinnedMessage.propTypes = {
  message: PropTypes.object.isRequired,
  unPin: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired
}

export default PinnedMessage
