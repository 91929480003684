import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPencil } from '@fortawesome/pro-solid-svg-icons'
import { toast } from 'react-toastify'

const CurrentStreamers = ({
  streamers,
  handleActivate,
  handleEdit,
  handleDelete,
  display
}) => {
  const displayClass =
    display === 'grid' ? 'grid grid-cols-1 xl:grid-cols-3' : 'flex flex-col'

  return (
    <div className={`${displayClass} gap-5`}>
      {streamers.map((strmr, index) => {
        return (
          <div key={index}>
            <div className="flex">
              <ActiveButton
                index={index}
                active={strmr.active}
                handleActivate={handleActivate}
              >
                <div className="mr-2 md:mr-4 shrink-0">
                  <img
                    className="streamer-pic w-16 h-16 border-0"
                    src={strmr.photo}
                    alt={strmr.name}
                  />
                </div>
                <div className="flex items-center justify-center gap-2">
                  <h3>{strmr.name}</h3>
                  {handleEdit && (
                    <EditButton handleEdit={() => handleEdit(index)} />
                  )}
                  {handleDelete && (
                    <DeleteButton handleDelete={() => handleDelete(index)} />
                  )}
                </div>
              </ActiveButton>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const DeleteButton = ({ handleDelete }) => {
  return (
    <button
      className="flex items-center justify-center rounded-full bg-red-500 dark:bg-red-700 text-white w-6 h-6 md:w-8 md:h-8"
      type="button"
      onClick={handleDelete}
    >
      <FontAwesomeIcon icon={faTrashCan} className="w-3 h-3 md:w-4 md:h-4" />
    </button>
  )
}

DeleteButton.propTypes = {
  handleDelete: PropTypes.func
}

const EditButton = ({ handleEdit }) => {
  return (
    <button
      className="flex items-center justify-center rounded-full bg-blue-500 dark:bg-blue-700 text-white w-6 h-6 md:w-8 md:h-8"
      type="button"
      onClick={handleEdit}
    >
      <FontAwesomeIcon icon={faPencil} className="w-3 h-3 md:w-4 md:h-4" />
    </button>
  )
}

EditButton.propTypes = {
  handleEdit: PropTypes.func
}

const handleSelect = (index, handleActivate) => {
  handleActivate(index)
  toast.success('Active Streamer updated.')
}

const ActiveButton = ({ children, index, active, handleActivate }) => {
  if (handleActivate) {
    return (
      <button
        className={`flex w-full ${
          active ? 'streamer-button' : 'button-outline-neutral'
        }`}
        type="button"
        onClick={() => handleSelect(index, handleActivate)}
      >
        {children}
      </button>
    )
  } else {
    return <>{children}</>
  }
}

ActiveButton.propTypes = {
  children: PropTypes.any.isRequired,
  index: PropTypes.number,
  active: PropTypes.bool,
  handleActivate: PropTypes.func
}

CurrentStreamers.propTypes = {
  streamers: PropTypes.array,
  handleActivate: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
}

export default CurrentStreamers
