import {
  faArrowUpRightFromSquare,
  faBadgeCheck,
  faChess,
  faChevronDown,
  faChevronUp,
  faCircleStar,
  faVanShuttle
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getFontAwesome } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

const CollapsibleDiv = ({
  title,
  children,
  collapsed,
  toggleCollapse,
  selectedIcon = null
}) => {
  return (
    <div
      className={`border ${
        collapsed
          ? 'border-neutral-300 dark:border-neutral-700'
          : 'border-blue-500'
      } dark:bg-neutral-800 rounded w-full max-h-96 overflow-y-auto ${
        !collapsed && 'absolute top-0 left-0'
      }`}
    >
      <div
        className={`flex items-center justify-between bg-white dark:bg-neutral-800 text-neutral-800 dark:text-white ${
          collapsed
            ? 'rounded'
            : 'rounded-t sticky top-0 border-b border-b-neutral-300 dark:border-neutral-700'
        } p-2 cursor-pointer`}
        onClick={toggleCollapse}
      >
        <div className="flex items-center justify-between">
          {selectedIcon && <FontAwesomeIcon icon={selectedIcon} />}
          <p
            className={
              selectedIcon &&
              'ml-2 pl-2 border-l border-l-neutral-300 dark:border-neutral-700'
            }
          >
            {title}
          </p>
        </div>

        <FontAwesomeIcon
          icon={collapsed ? faChevronDown : faChevronUp}
          size="xs"
        />
      </div>
      {!collapsed && (
        <div className="p-4 bg-white dark:bg-neutral-800">{children}</div>
      )}
    </div>
  )
}

CollapsibleDiv.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  toggleCollapse: PropTypes.func,
  selectedIcon: PropTypes.object
}

const DividerLabel = ({ title }) => {
  return (
    <div className="col-span-full">
      <div className="flex items-center">
        <hr className="flex-grow border-t border-neutral-300 dark:border-neutral-900" />
        <div className="px-4 py-1 bg-white dark:bg-neutral-900 rounded-full text-neutral-500">
          {title.toUpperCase()}
        </div>
        <hr className="flex-grow border-t border-neutral-300 dark:border-neutral-900" />
      </div>
    </div>
  )
}

DividerLabel.propTypes = {
  title: PropTypes.string.isRequired
}

const IconPicker = ({
  value,
  onChange,
  title,
  collapsed = false,
  renderSelected = false
}) => {
  const [customIcons, setCustomIcons] = useState({
    iconDefinitions: {},
    iconPrefixes: [],
    isLoading: true,
    isError: false
  })
  const [selectedIconName, setSelectedIconName] = useState(
    value?.iconName || ''
  )
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const containerRef = useRef(null)

  const FONT_AWESOME_ICONS = [
    faArrowUpRightFromSquare,
    faBadgeCheck,
    faVanShuttle,
    faChess,
    faCircleStar
  ]

  useEffect(() => {
    // fetch the custom icons
    const fetchCustomIcons = async () => {
      setCustomIcons({
        iconDefinitions: {},
        iconPrefixes: [],
        isLoading: true,
        isError: false
      })
      const response = await getFontAwesome()
      if (response && response.status === 200) {
        // for each custom icon grab the prefix and stash it in an array
        const iconPrefixes = []
        // for each icon upload we need to create an icon definition
        const iconDefinitions = {}
        response.data.iconUploads.forEach((icon) => {
          iconDefinitions[icon.name] = {
            prefix: 'fak',
            iconName: `${icon.name}`,
            icon: [
              parseInt(`${icon.width}`),
              parseInt(`${icon.height}`),
              [], // no ligatures
              icon.unicode.toString(16),
              `${icon.path}`
            ]
          }
          const prefix = icon.name.split('-')[0]
          if (!iconPrefixes.includes(prefix)) {
            iconPrefixes.push(prefix)
          }
        })
        setCustomIcons({
          iconDefinitions,
          iconPrefixes,
          isLoading: false,
          isError: false
        })
      } else {
        setCustomIcons({
          iconDefinitions: {},
          iconPrefixes: [],
          isLoading: false,
          isError: true
        })
      }
    }

    fetchCustomIcons()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsCollapsed(true)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef, setIsCollapsed])

  useEffect(() => {
    if (value?.iconName) {
      setSelectedIconName(value.iconName)
      const allIcons = [
        ...Object.values(customIcons.iconDefinitions),
        ...FONT_AWESOME_ICONS
      ]
      const icon = allIcons.find((icon) => icon.iconName === value.iconName)
      setSelectedIcon(icon)
    }
  }, [setSelectedIconName, setSelectedIcon, value, customIcons.isLoading])

  const handleIconSelect = (icon, isCustom = true) => {
    setSelectedIconName(isCustom ? icon : icon.iconName)
    onChange(isCustom ? customIcons.iconDefinitions[icon] : icon)
    setSelectedIcon(isCustom ? customIcons.iconDefinitions[icon] : icon)
    setIsCollapsed(true)
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div ref={containerRef} className="relative w-full" tabIndex={0}>
      <CollapsibleDiv
        title={title}
        collapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
        selectedIcon={renderSelected ? selectedIcon : null}
      >
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {customIcons.isLoading ? (
            <p>Loading...</p>
          ) : customIcons.isError ? (
            <p>Error loading icons</p>
          ) : (
            <>
              {/* Sort the prefixes alphabetically */}
              {customIcons.iconPrefixes &&
                customIcons.iconPrefixes
                  .sort((a, b) => a.localeCompare(b))
                  .map((prefix, index) => {
                    // grab the keys from the customIcons.iconDefinitions object that match the current prefix
                    const prefixIcons = Object.keys(
                      customIcons.iconDefinitions
                    ).filter((iconName) => iconName.startsWith(prefix))
                    // return the divider label and the buttons for each icon
                    return (
                      <React.Fragment key={index}>
                        <DividerLabel title={prefix} />
                        {prefixIcons.map((iconName, iconIndex) => (
                          <button
                            key={iconIndex}
                            type="button"
                            className={`p-4 flex flex-col items-center justify-center rounded-lg text-neutral-800 dark:text-neutral-100 ${
                              selectedIconName === iconName
                                ? 'bg-blue-500 text-white'
                                : 'bg-neutral-100 dark:bg-neutral-900 '
                            }`}
                            onClick={() => handleIconSelect(iconName)}
                          >
                            <FontAwesomeIcon
                              icon={customIcons.iconDefinitions[iconName]}
                              size="xl"
                            />
                          </button>
                        ))}
                      </React.Fragment>
                    )
                  })}
            </>
          )}
          <div className="col-span-full">
            <div className="flex items-center">
              <hr className="flex-grow border-t border-neutral-300 dark:border-neutral-900" />
              <div className="px-4 py-1 bg-white dark:bg-neutral-900 rounded-full text-neutral-500">
                FONT AWESOME
              </div>
              <hr className="flex-grow border-t border-neutral-300 dark:border-neutral-900" />
            </div>
          </div>
          {FONT_AWESOME_ICONS.map((icon, index) => (
            <button
              key={index}
              type="button"
              className={`p-4 flex flex-col items-center justify-center rounded-lg text-neutral-800 dark:text-neutral-100 ${
                selectedIconName === icon.iconName
                  ? 'bg-blue-500 text-white'
                  : 'bg-neutral-100 dark:bg-neutral-900 '
              }`}
              onClick={() => handleIconSelect(icon, false)}
            >
              <FontAwesomeIcon icon={icon} size="xl" />
            </button>
          ))}
        </div>
      </CollapsibleDiv>
    </div>
  )
}

IconPicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  title: PropTypes.string,
  collapsed: PropTypes.bool,
  renderSelected: PropTypes.bool
}

export default IconPicker
