import React, { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Header from '../../components/Layout/Header'
import Footer from '../../components/Layout/Footer'
import { setPageTitle } from '../../utils/utilities'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { Guide, Table } from '../../components/Layout/Portfolio'
import * as SETTINGS from '../../constants/settings'
import * as FEATURES from '../../constants/features'

const Portfolio = () => {
  setPageTitle('Portfolio')
  const {
    isLoading,
    [SETTINGS.FEATURES.key]: enabledFeatures,
    [SETTINGS.PORTFOLIO_ID.key]: id
  } = useContext(SiteConfigContext)
  const tradesEnabled = useMemo(
    () => enabledFeatures && enabledFeatures[FEATURES.TRADES],
    [enabledFeatures]
  )
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isLoading && !tradesEnabled) {
      const from = location.state?.from?.pathname || '/404'
      navigate(from, { replace: true })
    }
  }, [isLoading])

  return (
    <main className="flex h-screen max-h-screen flex-col overflow-hidden bg-neutral-100 dark:bg-neutral-900">
      <Header />
      <div className="flex flex-1 overflow-auto">
        <div className="flex flex-col gap-4 container mx-auto mb-auto p-2 md:py-10">
          <h1>Portfolio</h1>
          <Guide />
          {id && <Table portfolioId={id} />}
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Portfolio
