import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'

const HubButton = () => {
  const { [SETTINGS.HUB_BUTTON.key]: hubButton } = useContext(SiteConfigContext)

  if (!hubButton.link || !hubButton.text) {
    return null
  }

  return (
    <Link to={hubButton.link} target="_blank" rel="noopener noreferrer">
      <button className="header-button button-outline">
        <span className="hidden md:block">{hubButton.text}</span>
        {hubButton.icon && (
          <FontAwesomeIcon
            icon={hubButton.icon}
            className="header-button-icon"
            aria-hidden="true"
          />
        )}
      </button>
    </Link>
  )
}

export default HubButton
