import React, { useContext } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'

const PhoneNumbers = () => {
  const { [SETTINGS.PHONE_NUMBERS.key]: phoneNumbers, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...phoneNumbers,
      support01: formData.get('support01'),
      support02: formData.get('support02'),
      sales01: formData.get('sales01'),
      sales02: formData.get('sales02')
    }
    await updateSetting(SETTINGS.PHONE_NUMBERS, data)
    toast.success('Phone Numbers saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Phone Numbers</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="support01"
          name="support01"
          defaultValue={phoneNumbers.support01}
          className="input block w-full"
          placeholder="Support 01"
          required
        />
        <input
          id="support02"
          name="support02"
          defaultValue={phoneNumbers.support02}
          className="input block w-full"
          placeholder="Support 02"
        />
        <input
          id="sales01"
          name="sales01"
          defaultValue={phoneNumbers.sales01}
          className="input block w-full"
          placeholder="Sales 01"
          required
        />
        <input
          id="sales02"
          name="sales02"
          defaultValue={phoneNumbers.sales02}
          className="input block w-full"
          placeholder="Sales 02"
        />
        <button
          type="submit"
          aria-label="Save Phone Numbers"
          className="button-confirm w-fit"
        >
          Save
        </button>
      </form>
    </div>
  )
}

export default PhoneNumbers
