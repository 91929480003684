import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'
import {
  deleteBotMessage,
  getBotMessages,
  postBotMessage
} from '../../../utils/APIs/commandBotLegacy'
import { setPageTitle } from '../../../utils/utilities'

const TRIGGER_MAX_LENGTH = 15
const MESSAGE_MAX_LENGTH = 500

const CommandBotLegacy = () => {
  setPageTitle('Command Bot Legacy')
  const [messages, setMessages] = useState([])

  useEffect(() => {
    setUp()
  }, [])

  const setUp = async () => {
    const response = await getBotMessages()
    if (response && response.status === 200) {
      setMessages(response.data)
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 shrink">
        <h2>THESE NO LONGER WORK. LEAVING THIS HERE TO HELP US MIGRATE.</h2>
      </div>
      <div className="flex flex-col gap-2 shrink">
        <h2>Existing Messages</h2>
        <div className="relative overflow-x-auto pb-4">
          <div className="flex flex-col gap-1">
            <div className="flex justify-between gap-1 font-bold text-neutral-700 uppercase bg-neutral-100 dark:bg-neutral-700 dark:text-neutral-400">
              <p className="p-3 text-xs">Trigger</p>
              <p className="p-3 text-xs">Message</p>
              <p className="p-3 text-xs">Delete?</p>
            </div>
            <div className="text-neutral-500 dark:text-neutral-400 bg-white dark:bg-neutral-800 dark:border-neutral-700">
              {messages.map((msg) => {
                return (
                  <div
                    key={msg._id}
                    className="flex justify-start gap-1 border-b last:border-0 border-neutral-100 dark:border-neutral-700 max-w-full"
                  >
                    <p className="p-3 text-sm break-words w-48 max-w-auto">
                      {msg.trigger}
                    </p>
                    <p className="p-3 text-sm break-words w-full max-w-auto overflow-x-auto">
                      {msg.message}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AddMessageForm = ({ messages, handleOnAdd }) => {
  const [trigger, setTrigger] = useState('')
  const [message, setMessage] = useState('')
  const toastError =
    'Error adding command. Please verify that the Trigger and Message are valid.'

  const handleTriggerChange = (value) => {
    value = value.replace(/[^A-Za-z1-9]/gi, '')
    setTrigger(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const match = messages.find((each) => `${each.trigger}` === trigger)
    if (match) {
      toast.error(toastError)
    } else {
      const response = await postBotMessage(trigger, message)
      if (response && response.status === 201) {
        handleOnAdd(response.data)
        setTrigger('')
        setMessage('')
        toast.success('Bot command added.')
      } else {
        toast.error(toastError)
      }
    }
  }

  return (
    <div className="mb-auto">
      <form
        aria-label="Add bot message"
        onSubmit={handleSubmit}
        autoComplete="off"
        className="relative w-full flex flex-col lg:flex-row flex-grow items-start focus-within:z-10 gap-2"
      >
        <div className="h-fit w-full">
          <h4 className="mb-1">
            Trigger
            <span className="ml-2 text-xs">
              <span
                className={
                  trigger.length === TRIGGER_MAX_LENGTH
                    ? 'text-red-500'
                    : 'text-neutral-500'
                }
              >
                ({trigger.length} / {TRIGGER_MAX_LENGTH})
              </span>
            </span>
          </h4>
          <input
            aria-label="Input bot trigger"
            value={trigger}
            onChange={(e) => handleTriggerChange(e.target.value)}
            className="input w-full"
            placeholder="Enter a trigger..."
            maxLength={TRIGGER_MAX_LENGTH}
          />
        </div>
        <div className="h-fit w-full">
          <h4 className="mb-1">
            Message
            <span className="ml-2 text-xs">
              <span
                className={
                  message.length === MESSAGE_MAX_LENGTH
                    ? 'text-red-500'
                    : 'text-neutral-500'
                }
              >
                ({message.length} / {MESSAGE_MAX_LENGTH})
              </span>
            </span>
          </h4>
          <TextareaAutosize
            aria-label="Input bot message"
            id="input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="input block w-full flex-auto h-[2.6rem] min-h-[2.6rem] "
            placeholder="Enter a message..."
            minRows={1}
            maxRows={4}
            autoComplete="off"
            maxLength={MESSAGE_MAX_LENGTH}
          />
        </div>
        <button
          type="submit"
          aria-label="Submit bot trigger and message"
          className="button-confirm relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0 lg:mt-8"
        >
          Submit
        </button>
      </form>
    </div>
  )
}

AddMessageForm.propTypes = {
  messages: PropTypes.array,
  handleOnAdd: PropTypes.func.isRequired
}

const DeleteMessageForm = ({ message, handleOnDelete }) => {
  const [confirm, setConfirm] = useState(false)

  const handleOnClick = (value) => {
    setConfirm(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const response = await deleteBotMessage(message._id)
    if (response && response.status === 200) {
      setConfirm(false)
      handleOnDelete(message._id)
      toast.success('Bot command deleted.')
    }
  }

  if (confirm) {
    return (
      <div className="flex gap-1 items-start justify-end p-3 ml-auto">
        <button
          className="flex justify-around text-white rounded-full items-center bg-green-500 hover:bg-green-600 active:bg-green-700 h-6 w-6"
          onClick={handleSubmit}
        >
          <FontAwesomeIcon className="h-3.5 w-3.5" icon={faCheck} />
        </button>
        <button
          className="flex justify-around text-white rounded-full items-center bg-red-500 hover:bg-red-600 active:bg-red-700 h-6 w-6"
          onClick={() => handleOnClick(false)}
        >
          <FontAwesomeIcon className="h-3.5 w-3.5" icon={faTimes} />
        </button>
      </div>
    )
  }

  return (
    <div className="flex gap-1 items-start justify-end p-3 ml-auto">
      <button
        className="flex justify-around text-white rounded-full items-center bg-red-500 hover:bg-red-600 active:bg-red-700 h-6 w-6"
        onClick={() => handleOnClick(true)}
      >
        <FontAwesomeIcon className="h-3.5 w-3.5" icon={faTimes} />
      </button>
    </div>
  )
}

DeleteMessageForm.propTypes = {
  message: PropTypes.object.isRequired,
  handleOnDelete: PropTypes.func.isRequired
}

export default CommandBotLegacy
