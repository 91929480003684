import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import UploadImages from '../../../Forms/common/UploadImages'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'

const Graphics = () => {
  setPageTitle('Content | Graphics')
  const { [SETTINGS.IMAGES.key]: images, updateSetting } =
    useContext(SiteConfigContext)

  const setImage = async (type, image, reason) => {
    const copy = { ...images }
    copy[type] = image
    await updateSetting(SETTINGS.IMAGES, copy)
    toast.success(`Image/s ${reason} successfully!`)
  }

  const updateLogo = (image) => {
    setImage('logo', image, 'Saved')
  }
  const updateLogoDark = (image) => {
    setImage('logoDark', image, 'Saved')
  }
  const updateOffline = (image) => {
    setImage('offline', image, 'Saved')
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 h-full w-fit gap-2">
      <ImageSection
        title="Logo (Light Mode)"
        image={images.logo}
        handleDelete={() => setImage('logo', '', 'Deleted')}
        returnImage={updateLogo}
      />
      <ImageSection
        title="Logo (Dark Mode)"
        image={images.logoDark}
        handleDelete={() => setImage('logoDark', '', 'Deleted')}
        returnImage={updateLogoDark}
        dark
      />
      <ImageSection
        title="Stream Offline"
        image={images.offline}
        handleDelete={() => setImage('offline', '', 'Deleted')}
        returnImage={updateOffline}
      />
    </div>
  )
}

const ImageSection = ({ title, image, handleDelete, returnImage, dark }) => {
  return (
    <div className="col-span-1 w-fit lg:p-5 flex flex-col gap-5">
      <h2 className="mb-1">{title}</h2>
      {image && (
        <div className="flex flex-col gap-5">
          <img
            src={image}
            alt="Image"
            className={`${dark ? 'bg-black' : 'bg-white'} p-5`}
          />
          <button
            onClick={handleDelete}
            className="w-fit text-xs button-cancel"
          >
            Remove
          </button>
        </div>
      )}
      <UploadImages returnImages={returnImage} single />
    </div>
  )
}

ImageSection.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  returnImage: PropTypes.func.isRequired
}

export default Graphics
