import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faExclamation } from '@fortawesome/pro-solid-svg-icons'
import { getRoles } from '../../../utils/APIs/roles'
import { putUser } from '../../../utils/APIs/users'
import { toast } from 'react-toastify'

const ChangeRoleForm = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [newRole, setNewRole] = useState()

  const [roles, setRoles] = useState()

  useEffect(() => {
    loadRoles()
  }, [])

  useEffect(() => {
    setNewRole(user.role._id)
  }, [user])

  const loadRoles = async () => {
    setLoading(true)
    const response = await getRoles()
    if (response && response.status === 200) {
      setRoles(response.data)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  const handleSubmitRoleChange = async (e) => {
    e.preventDefault()
    setLoading(true)
    const response = await putUser(user._id, 'role', newRole)
    if (response && response.status === 200) {
      toast.success('Role changed.')
    } else {
      setError(true)
      toast.error('Error changing role.')
    }
    setLoading(false)
  }

  return (
    <div className="mb-auto flex flex-col gap-2">
      {user && roles && (
        <form
          onSubmit={handleSubmitRoleChange}
          autoComplete="off"
          className="flex gap-2"
        >
          <div className="relative flex flex-grow focus-within:z-10 gap-2 items-center">
            <select
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              className="grow input"
            >
              {roles.map((role) => {
                return (
                  <option key={role.role} value={role._id}>
                    {role.role}
                  </option>
                )
              })}
            </select>
            <button
              type="submit"
              aria-label="Submit Role"
              className="button-confirm"
            >
              Change Role
            </button>
          </div>
        </form>
      )}
      {loading && (
        <div className="flex items-center whitespace-nowrap bg-green-500 px-4 py-2 font-bold text-white transition-all hover:bg-green-600 active:bg-green-700">
          <FontAwesomeIcon
            className="mr-2 h-5 cursor-pointer"
            icon={faSpinner}
            spin
          />
        </div>
      )}
      {error && (
        <div className="flex items-center whitespace-nowrap bg-red-500 px-4 py-2 font-bold text-white transition-all hover:bg-red-600 active:bg-red-700">
          <FontAwesomeIcon
            className="mr-2 h-5 cursor-pointer"
            icon={faExclamation}
          />
        </div>
      )}
    </div>
  )
}

ChangeRoleForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default ChangeRoleForm
