import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Modal from '../../Utilities/Modal'
import { BanControls, TimeoutControls } from '../../Utilities/UserBio/UserBio'

const ModModal = ({ open, setOpen, message }) => {
  const [user, setUser] = useState(message.user)

  return (
    <Modal
      title="Mod Controls"
      open={open}
      setOpen={setOpen}
      size="sm"
      cancelText="Close"
    >
      <div className="flex flex-col gap-2 p-4 dark:bg-neutral-800 dark:text-neutral-100 bg-neutral-100 border dark:border-neutral-700 rounded-md">
        <BanControls user={user} setUser={setUser} />
        <TimeoutControls user={user} setUser={setUser} />
        <Link
          className="button-outline w-fit ml-auto"
          to={`/mod/private-messages?id=${user.userService._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to {user.userService.profile.name}&rsquo;s profile
        </Link>
      </div>
    </Modal>
  )
}

ModModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
}

export default ModModal
