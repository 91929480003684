import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { ColorPicker } from '../../../../utils/colors'
import IconPicker from '../../../Utilities/IconPicker'
import { postBadge, putBadge } from '../../../../utils/APIs/badges'

const EMPTY_BADGE = { name: '', title: '', color: '', icon: null }

function BadgeBuilder({
  badgesObj: { badges, isLoading, isError },
  fetchBadges
}) {
  const [badge, setBadge] = useState(EMPTY_BADGE)

  const handleSubmit = async (e) => {
    e.preventDefault()
    // is this an existing badge?
    if (badges && badges.length > 0) {
      // find existing badge
      const { _id } = badges.find(
        (existingBadge) => existingBadge.name === badge.name
      ) || { _id: null }
      if (_id) {
        // update existing badge
        await putBadge({ ...badge, _id })
        await fetchBadges()
        setBadge(EMPTY_BADGE)
      } else {
        // create new badge
        await postBadge(badge)
        await fetchBadges()
        setBadge(EMPTY_BADGE)
      }
    } else {
      // create new badge
      await postBadge(badge)
      await fetchBadges()
      setBadge(EMPTY_BADGE)
    }
  }

  return (
    <>
      <h2>Badge Builder</h2>
      <h3>Existing badges:</h3>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError ? (
        <p>Error loading badges</p>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {badges &&
            badges.length > 0 &&
            badges.map((existingBadge, index) => (
              <button
                key={index}
                type="button"
                className={`${
                  existingBadge.name === badge.name
                    ? 'border-blue-500 drop-shadow-lg'
                    : 'border-transparent drop-shadow'
                } bg-neutral-100 dark:bg-neutral-800 border p-4 flex flex-col items-center justify-center rounded-lg text-${
                  existingBadge.color
                }-500`}
                onClick={() => {
                  const { ...rest } = existingBadge
                  setBadge(rest)
                }}
              >
                <FontAwesomeIcon icon={existingBadge.icon} size="xl" />
                <span className="text-xs">{existingBadge.title}</span>
              </button>
            ))}
        </div>
      )}
      <h3>Add new badge:</h3>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
        <div className="drop-shadow bg-neutral-100 dark:bg-neutral-800 p-4 flex flex-col items-center justify-center rounded-lg">
          <FontAwesomeIcon
            className={
              badge.color !== '' ? `text-${badge.color}-500` : 'text-black'
            }
            icon={badge.icon || faQuestionCircle}
            size="xl"
          />
          <p className={`text-${badge.color}-500 text-xs text-center`}>
            {badge.title}
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-2 pb-4">
        <label htmlFor="badge-title">
          <p>Badge Title</p>
        </label>
        <input
          className="input w-fit"
          id="badge-title"
          name="badge-title"
          type="text"
          placeholder="Title"
          value={badge.title}
          onChange={(e) =>
            setBadge((badge) => ({
              ...badge,
              title: e.target.value,
              name: e.target.value.toLowerCase().replace(/\s/g, '-')
            }))
          }
        />
        <label htmlFor="badge-color">
          <p>Badge Color</p>
        </label>
        <ColorPicker
          id="badge-color"
          name="badge-color"
          shades={[500]}
          handleSelect={(color) =>
            setBadge((badge) => ({
              ...badge,
              color: color.split('-')[0]
            }))
          }
        />
        <IconPicker
          value={badge.icon}
          onChange={(icon) => setBadge((badge) => ({ ...badge, icon }))}
          title="Badge Icon"
          collapsed
          renderSelected
        />
        <button type="submit" className="button-confirm w-fit">
          Submit
        </button>
      </form>
    </>
  )
}

BadgeBuilder.propTypes = {
  badgesObj: PropTypes.object.isRequired,
  fetchBadges: PropTypes.func.isRequired
}

export default BadgeBuilder
