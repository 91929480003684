import React from 'react'
import { setPageTitle } from '../../../utils/utilities'

import PostPMKTradeForm from '../../Forms/PostPMKTradeForm'

const PostPMKTrade = () => {
  setPageTitle('Post PMK Trade')

  return (
    <div className="flex w-full">
      <PostPMKTradeForm />
    </div>
  )
}

export default PostPMKTrade
