import React, { useContext, useState } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'
import IconPicker from '../../../Utilities/IconPicker'
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CustomButton = () => {
  const { [SETTINGS.CUSTOM_BUTTON.key]: customButton, updateSetting } =
    useContext(SiteConfigContext)
  const [icon, setIcon] = useState(customButton.icon)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      text: formData.get('text'),
      link: formData.get('link'),
      icon,
      emphasize: formData.get('emphasize') === 'on'
    }
    await updateSetting(SETTINGS.CUSTOM_BUTTON, data)
    toast.success('Custom Button saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Custom Button</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="grid grid-cols-1 md:grid-cols-2 gap-2"
      >
        <input
          aria-label="Custom Button Text"
          id="text"
          name="text"
          defaultValue={customButton.text}
          className="input block w-full"
          placeholder="Text"
        />
        <input
          aria-label="Custom Button Link"
          id="link"
          name="link"
          defaultValue={customButton.link}
          className="input block w-full"
          placeholder="Link"
        />
        <div className="md:col-span-2 flex gap-2">
          <IconPicker
            value={icon}
            title="Custom Button Icon"
            renderSelected
            collapsed
            onChange={(icon) => setIcon(icon)}
          />
          <button
            type="submit"
            aria-label="Save Custom Button"
            className="button-confirm w-fit"
          >
            Save
          </button>
          <label
            className={`cursor-pointer flex w-fit items-center justify-between px-2 py-1 gap-1.5 rounded border transition-all ${
              customButton.emphasize
                ? 'bg-green-500/15 hover:bg-green-500/25 border-green-500/50'
                : 'bg-red-500/15 hover:bg-red-500/25 border-red-500/50'
            }`}
          >
            <p className="select-none">Emphasize</p>
            <input
              type="checkbox"
              name="emphasize"
              checked={customButton.emphasize}
              onChange={(e) =>
                updateSetting(SETTINGS.CUSTOM_BUTTON, {
                  ...customButton,
                  emphasize: e.target.checked
                })
              }
              className="hidden"
            />
            <p className="sr-only">{customButton.emphasize ? `on` : `off`}</p>
            <FontAwesomeIcon
              icon={customButton.emphasize ? faCircleCheck : faCircleXmark}
              className={`${customButton.emphasize ? 'text-green-500' : 'text-red-500'}`}
            />
          </label>
        </div>
      </form>
    </div>
  )
}

export default CustomButton
