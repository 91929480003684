import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons'
import TextWrapper from '../../Utilities/TextWrapper'
import { HeadquartersButton } from '../../Buttons/HeadquartersButton'
import Schedule from '../Schedule'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import Footer from '../Footer'

const StreamInfoPanel = () => {
  const {
    [SETTINGS.STREAM_TITLE.key]: streamTitle,
    [SETTINGS.LIVESTREAM_LIVE.key]: livestreamLive,
    activeStreamer
  } = useContext(SiteConfigContext)

  return (
    <div className="w-full md:h-full flex flex-col px-2 py-1 lg:p-5 mx-auto mb-0 md:mb-2 bg-white dark:bg-black md:bg-neutral-100 md:dark:bg-neutral-950 md:rounded-xl md:border dark:border-neutral-900">
      <div className="w-full md:h-full md:container flex flex-col items-center gap-2.5 md:gap-5">
        <div className="w-full flex items-center gap-2.5 md:gap-5">
          <img
            className={`streamer-pic ${
              livestreamLive ? 'border-red-600' : 'border-neutral-600'
            }`}
            src={activeStreamer?.photo}
            alt=""
          />
          <div className="flex flex-col gap-0 md:gap-2">
            <h2>
              <TextWrapper text={streamTitle} almdreen />
            </h2>
            <h4>{activeStreamer?.name}</h4>
          </div>
        </div>
        <div className="hidden w-full md:flex md:flex-col gap-5">
          <Schedule />
          <Headquarters />
        </div>
        <div className="hidden md:block mt-auto">
          <Footer />
        </div>
      </div>
    </div>
  )
}

const Headquarters = () => {
  const { [SETTINGS.HEADQUARTERS.key]: headquarters } =
    useContext(SiteConfigContext)

  if (headquarters.length === 0) {
    return null
  }
  return (
    <div>
      <div className="col-span-3 mb-2 w-full">
        <h3 className="border-b-2 border-brand-primary-500 w-fit pb-1">
          <FontAwesomeIcon icon={faBuilding} className="mr-3 h-6" />
          Headquarters
        </h3>
      </div>
      <div className="cards max-w-full gap-4 grid grid-cols-3 items-stretch">
        {headquarters.map((HQ, index) => {
          return (
            <HeadquartersButton
              key={index}
              title={HQ.title}
              link={HQ.link}
              content={HQ.content}
              icon={HQ.icon}
              primary={HQ.primary}
            />
          )
        })}
      </div>
    </div>
  )
}

export default StreamInfoPanel
