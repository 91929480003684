import React, { useState, useEffect, useContext } from 'react'
import { getRoles, postRole } from '../../../utils/APIs/roles'
import { getUserByEmail, putUser } from '../../../utils/APIs/users'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'
import * as ROLES from '../../../constants/roles'

const InitializeSettings = () => {
  return (
    <div className="flex flex-col gap-2">
      <InitializeRoles />
    </div>
  )
}

const InitializeRoles = () => {
  const [roles, setRoles] = useState()
  const [missing, setMissing] = useState(true)

  useEffect(() => {
    loadRoles()
  }, [])

  const loadRoles = async () => {
    const response = await getRoles()
    if (response && response.status === 200) {
      setRoles(response.data)
      setMissing(response.data.length === 0)
    }
  }

  const handleInitializeRoles = async () => {
    ROLES.ALL_ROLES.forEach((role) => {
      postRole(role.toLowerCase())
    })
  }

  return (
    <div className="flex flex-col gap-0.5">
      <p>Existing Roles: {JSON.stringify(roles?.length)}</p>
      {!missing && <p>Roles are good to go!</p>}
      <button
        onClick={() => handleInitializeRoles()}
        aria-label="Initialize Settings"
        className="button-fill w-fit"
        disabled={!missing}
      >
        Initialize Roles
      </button>
      {!missing && <InitializeAdmin />}
    </div>
  )
}

const InitializeAdmin = () => {
  const { updateSetting } = useContext(SiteConfigContext)
  const [adminRole, setAdminRole] = useState()
  const [email, setEmail] = useState('')
  const [userID, setUserID] = useState()

  useEffect(() => {
    loadRoles()
  }, [])

  const loadRoles = async () => {
    const response = await getRoles()
    if (response && response.status === 200) {
      const admin = response.data.find((role) => role.role === ROLES.ADMIN)
      setAdminRole(admin._id)
    }
  }

  const handleGetUser = async (e) => {
    e.preventDefault()
    if (email) {
      const response = await getUserByEmail(email)
      if (response && response.status === 200 && response.data) {
        setUserID(response.data._id)
      }
    }
  }

  const handleSetAdmin = async () => {
    const response = await putUser(userID, 'role', adminRole)
    if (response && response.status === 200) {
      await updateSetting(SETTINGS.SETUP_COMPLETE, true)
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <p>{adminRole}</p>
      <form
        onSubmit={handleGetUser}
        autoComplete="off"
        className="flex focus-within:z-10 gap-2"
      >
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input w-fit"
          placeholder="User Email"
        />
        <button
          type="submit"
          aria-label="Get User"
          className="button-confirm w-fit relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0"
        >
          Get User
        </button>
      </form>
      <button
        onClick={() => handleSetAdmin()}
        aria-label="Set Admin"
        className="button-confirm w-fit"
        disabled={!userID}
      >
        Set Admin
      </button>
    </div>
  )
}

export default InitializeSettings
