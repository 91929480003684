import * as C_FEATURES from './features'

export const FEATURES_DEFAULT = {}
FEATURES_DEFAULT[C_FEATURES.TRADES] = false
FEATURES_DEFAULT[C_FEATURES.POLLS] = false
FEATURES_DEFAULT[C_FEATURES.COMMAND_BOT] = false
FEATURES_DEFAULT[C_FEATURES.AD_BUILDER] = false
FEATURES_DEFAULT[C_FEATURES.HUB] = false

// SETTINGS CONSTS
export const ALL_SETTINGS = []

export const BANNER_DATA = {
  key: 'bannerdata',
  default: {
    openHouseOnly: false,
    showCountdown: true,
    endDateTime: new Date().toString(),
    promoLink: '',
    countdownText: '',
    body: '',
    showButton: true,
    buttonText: '',
    bgColor: 'yellow',
    buttonColor: 'green'
  }
}
ALL_SETTINGS.push(BANNER_DATA)

export const CHAT_OPEN = { key: 'chatopen', default: false }
ALL_SETTINGS.push(CHAT_OPEN)

export const DISPLAY_BANNER = { key: 'displaybanner', default: false }
ALL_SETTINGS.push(DISPLAY_BANNER)

export const FEATURES = { key: 'features', default: FEATURES_DEFAULT }
ALL_SETTINGS.push(FEATURES)

export const SETUP_COMPLETE = { key: 'setupcomplete', default: false }
ALL_SETTINGS.push(SETUP_COMPLETE)

export const LIVESTREAM_LIVE = { key: 'livestreamlive', default: false }
ALL_SETTINGS.push(LIVESTREAM_LIVE)

export const STREAM_TITLE = {
  key: 'streamtitle',
  default: 'Welcome to the livestream!'
}
ALL_SETTINGS.push(STREAM_TITLE)

export const PINNED_MESSAGE = { key: 'pinnedmessage', default: null }
ALL_SETTINGS.push(PINNED_MESSAGE)

export const SCHEDULE = {
  key: 'schedule',
  default: {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: []
  }
}
ALL_SETTINGS.push(SCHEDULE)

export const STREAMERS = { key: 'streamers', default: [] }
ALL_SETTINGS.push(STREAMERS)

export const HEADQUARTERS = { key: 'headquarters', default: [] }
ALL_SETTINGS.push(HEADQUARTERS)

export const VIEWER_COUNT = { key: 'viewercount', default: 0 }
ALL_SETTINGS.push(VIEWER_COUNT)

export const STREAM_OFFLINE = {
  key: 'streamoffline',
  default: {
    text: 'The stream is offline.',
    link: ''
  }
}
ALL_SETTINGS.push(STREAM_OFFLINE)

export const PASSWORD_RESET = { key: 'passwordreset', default: '' }
ALL_SETTINGS.push(PASSWORD_RESET)

export const WELCOME_VIDEO = {
  key: 'welcomevideo',
  default: {
    wistiaId: '',
    blurb: ''
  }
}
ALL_SETTINGS.push(WELCOME_VIDEO)

export const PHONE_NUMBERS = {
  key: 'phonenumbers',
  default: {
    support01: '',
    support02: '',
    sales01: '',
    sales02: ''
  }
}
ALL_SETTINGS.push(PHONE_NUMBERS)

export const HUB_BUTTON = {
  key: 'suitebutton',
  default: {
    text: '',
    link: '',
    icon: null
  }
}
ALL_SETTINGS.push(HUB_BUTTON)

export const CUSTOM_BUTTON = {
  key: 'custombutton',
  default: {
    text: '',
    link: '',
    icon: null,
    emphasize: false
  }
}
ALL_SETTINGS.push(CUSTOM_BUTTON)

export const TERMS_AND_CONDITIONS = {
  key: 'termsandconditions',
  default: 'Terms & Conditions'
}
ALL_SETTINGS.push(TERMS_AND_CONDITIONS)

export const COPYRIGHT = { key: 'copyright', default: 'Copyright' }
ALL_SETTINGS.push(COPYRIGHT)

// THIS WAS RENAMED TO MEMBERSHIPS TO NOT BE CONFUSED WITH THE PRODUCTS MICROSERVICE
export const MEMBERSHIPS = {
  key: 'products',
  default: {
    main: [],
    additional: []
  }
}
ALL_SETTINGS.push(MEMBERSHIPS)

export const IMAGES = {
  key: 'images',
  default: {
    logo: '',
    logoDark: '',
    offline: ''
  }
}
ALL_SETTINGS.push(IMAGES)

export const PORTFOLIO_ID = { key: 'portfolioid', default: '' }
ALL_SETTINGS.push(PORTFOLIO_ID)

export const ONESIGNAL_APP_ID = { key: 'onesignalappid', default: '' }
ALL_SETTINGS.push(ONESIGNAL_APP_ID)

export const AUDIO_HELP_GUIDE = { key: 'audiohelpguide', default: '' }
ALL_SETTINGS.push(AUDIO_HELP_GUIDE)

export const HEADQUARTERS_FREE = { key: 'headquartersfree', default: '' }
ALL_SETTINGS.push(HEADQUARTERS_FREE)

export const PORTFOLIO_GUIDE = { key: 'portfolioguide', default: '' }
ALL_SETTINGS.push(PORTFOLIO_GUIDE)

export const PORTFOLIO = {
  key: 'portfolio',
  default: {
    group: false,
    name: true,
    symbol: true,
    expirationDate: true,
    openDate: true,
    openPrice: true,
    currentPrice: true,
    stopPrice: true,
    gain: true,
    comments: true
  }
}
ALL_SETTINGS.push(PORTFOLIO)
