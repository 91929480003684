import {
  faCheckCircle,
  faHexagonExclamation,
  faInfoCircle,
  faReply,
  faTimesCircle,
  faTimes,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { postPrivateMessage, updatePrivateMessage } from '@moal/api'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import {
  ADD_MESSAGE,
  EDIT_MESSAGE
} from '../../../constants/actions/messageListActions'
import { CATEGORIES } from '../../../constants/analytics'
import { EDIT, SEND } from '../../../constants/chatForms'
import { MODERATORS } from '../../../constants/chatRooms'
import * as REGEX from '../../../constants/regex'
import * as SETTINGS from '../../../constants/settings'
import { AnalyticsContext } from '../../../context/analyticsContext'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import {
  getPublicMessageUser,
  postPublicMessage,
  putPublicMessage
} from '../../../utils/APIs/publicMessages'
import { PRODUCT_CODE } from '../../../utils/ENV'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { modNameChanger } from '../../../utils/utilities'
import Countdown from '../../Utilities/Countdown'

const MAX_MESSAGE_LENGTH = 500

const ChatForm = ({
  channel,
  type,
  id,
  prefill,
  handleCancel,
  toUser,
  dispatch = null
}) => {
  const { pushEvent } = useContext(AnalyticsContext)
  const { user, endTimeOut } = useContext(AuthenticationContext)
  const {
    replyTo,
    setReplyTo,
    [SETTINGS.CHAT_OPEN.key]: chatOpen
  } = useContext(SiteConfigContext)
  const theInput = useRef()
  const [message, setMessage] = useState(prefill || '')
  // An object with type and message to display above the chat form
  // type can be one of 'error', 'success', 'warning, or 'info'
  const [alert, setAlert] = useState(null)
  const [loading, setLoading] = useState(false)

  // Component did mount
  useEffect(() => {
    // We will check here to see if the user has ever posted a message before
    // If they have not, we will display a message to them
    const checkMessageHistory = async () => {
      try {
        // Get the user's public message history
        const response = await getPublicMessageUser(user._id)
        // If the response is successful and there is data
        if (response && response.status === 200) {
          // If the data is an array and the length is 0
          if (response.data.length === 0) {
            // Display a welcome message to the user and ask them to say hi
            setAlert({
              type: 'info',
              message: 'Welcome! Introduce yourself...'
            })
          }
        }
      } catch (error) {
        // If there is an error, log it
        console.error(error)
      }
    }
    // Call the function
    if (channel !== MODERATORS) {
      checkMessageHistory()
    } else {
      setAlert(null)
    }
  }, [channel])

  useEffect(() => {
    if (replyTo || !loading) {
      theInput.current?.focus()
    }
  }, [replyTo, loading])

  const handleKeyDown = (e) => {
    if (message.length >= MAX_MESSAGE_LENGTH) {
      setAlert({
        type: 'error',
        message: 'Messages are limited to 500 characters.'
      })
    } else {
      setAlert(null)
    }

    if (e.keyCode === 13) {
      e.preventDefault()
      send()
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    send()
  }

  const send = async () => {
    setLoading(true)
    switch (type) {
      case SEND:
        if (channel === MODERATORS) {
          handlePrivateSend()
        } else {
          handlePublicSend()
          setReplyTo(null)
        }
        break
      case EDIT:
        if (channel === MODERATORS) {
          handlePrivateEdit()
        } else {
          handlePublicEdit()
        }
        break
    }
  }

  const handlePublicSend = async () => {
    const success = preSubmitCheck()
    if (success) {
      const response = await postPublicMessage({
        user,
        message,
        replyTo: replyTo ? replyTo._id : null
      })
      setMessage('')
      setAlert(null)
      if (response && response.status === 201) {
        pushEvent(CATEGORIES.PUBLIC_MESSAGE, 'POST', user.userService._id)
      }
    }
    setLoading(false)
  }

  const handlePublicEdit = async () => {
    const success = preSubmitCheck()
    if (success) {
      const response = await putPublicMessage({
        id,
        user,
        message
      })
      setMessage('')
      setAlert(null)
      handleCancel()
      if (response && response.status === 200) {
        pushEvent(CATEGORIES.PUBLIC_MESSAGE, 'PUT', user.userService._id)
      }
    }
    setLoading(false)
  }

  const handlePrivateSend = async () => {
    const success = preSubmitCheck()
    if (success) {
      const data = {
        message
      }
      if (toUser) {
        data.toUser = toUser
      } else {
        data.itemNumber = PRODUCT_CODE
      }
      const response = await postPrivateMessage({
        token: localStorage.getItem('authToken'),
        ...data
      })
      if (response && response.status === 201) {
        if (dispatch) {
          dispatch({ type: ADD_MESSAGE, payload: response.data })
        }
        setMessage('')
        pushEvent(CATEGORIES.PRIVATE_MESSAGE, 'POST', user.userService._id)
      } else {
        setAlert({
          type: 'error',
          message: response.data.errors[0].message || 'Something went wrong...'
        })
      }
    }
    setLoading(false)
  }

  const handlePrivateEdit = async () => {
    const success = preSubmitCheck()
    if (success) {
      const response = await updatePrivateMessage({
        token: localStorage.getItem('authToken'),
        id,
        message
      })
      if (response && response.status === 200) {
        if (dispatch) {
          dispatch({ type: EDIT_MESSAGE, payload: response.data })
        }
        pushEvent(CATEGORIES.PRIVATE_MESSAGE, 'PUT', user.userService._id)
      }

      setMessage('')
      setAlert(null)
      handleCancel()
    }
    setLoading(false)
  }

  const preSubmitCheck = () => {
    if (!hasStaffPrivilege(user, true)) {
      const matches = message.match(REGEX.URL)
      if (matches) {
        setAlert({ type: 'error', message: 'URLs are not allowed in chat.' })
        return false
      }
    }

    if (message === '') {
      setAlert({ type: 'error', message: 'Please enter a message.' })
      return false
    }

    return true
  }

  if (user.moderation.banned && channel !== MODERATORS) {
    return (
      <div className="flex flex-row gap-2">
        <div className="rounded-lg input flex-auto w-96 bg-amber-500 dark:bg-amber-800">
          <p>
            <strong>
              A recent comment you&rsquo;ve made violated the Terms and
              Conditions. As a result, we&rsquo;ve temporarily removed your
              ability to post comments in chat. Please contact the Moderator for
              more details.
            </strong>
          </p>
        </div>
      </div>
    )
  }

  if (user.moderation.timeout && channel !== MODERATORS) {
    return (
      <div className="flex flex-row gap-2">
        <div className="rounded-lg input flex-auto w-96 bg-amber-500 dark:bg-amber-800">
          <p>
            <strong>
              You are timed out for:{' '}
              <Countdown
                endDate={user.moderation.timeoutEnd}
                endEvent={endTimeOut}
              />
            </strong>
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      {alert && <Alert {...alert} />}
      {replyTo && <ReplyingTo message={replyTo} handleClose={setReplyTo} />}
      <form
        id="chat-form"
        onSubmit={handleSubmit}
        autoComplete="off"
        className="relative flex items-stretch z-20 flex-col w-full"
      >
        <div className="flex flex-col w-full">
          <TextareaAutosize
            enterkeyhint="Send"
            aria-label="Chat form input"
            ref={theInput}
            id="input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            className="input !border-t !border-x-0 !border-b-0 md:!border !rounded-none md:!rounded-xl block w-full flex-auto h-[2.6rem] min-h-[2.6rem] max-h-96"
            placeholder="Type something..."
            disabled={
              !(chatOpen || (!chatOpen && hasStaffPrivilege(user))) || loading
            }
            minRows={1}
            maxRows={4}
            autoComplete="off"
            maxLength={MAX_MESSAGE_LENGTH}
          />
          <button
            type="submit"
            aria-label="Submit message"
            className="sr-only"
            disabled={
              !(chatOpen || (!chatOpen && hasStaffPrivilege(user))) || loading
            }
          >
            Send
          </button>
        </div>
      </form>
    </>
  )
}

ChatForm.propTypes = {
  channel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  prefill: PropTypes.string,
  handleCancel: PropTypes.func,
  toUser: PropTypes.string,
  dispatch: PropTypes.func
}

const Alert = ({ message, type }) => {
  const [show, setShow] = useState(true)

  const colors = {
    error: {
      bg: 'bg-red-600 dark:bg-red-900',
      text: 'text-white dark:text-red-200'
    },
    warning: {
      bg: 'bg-yellow-600 dark:bg-yellow-900',
      text: 'text-white dark:text-yellow-200'
    },
    info: {
      bg: 'bg-blue-600 dark:bg-blue-900',
      text: 'text-white dark:text-blue-200'
    },
    success: {
      bg: 'bg-green-600 dark:bg-green-900',
      text: 'text-white dark:text-green-200'
    }
  }

  const icons = {
    error: faHexagonExclamation,
    warning: faTriangleExclamation,
    info: faInfoCircle,
    success: faCheckCircle
  }

  const handleClose = () => {
    setShow(false)
  }

  if (!show) {
    return null
  }

  return (
    <div className="mb-2">
      <div
        className={`rounded-xl w-full flex items-center p-2 transition-all shadow ${colors[type].bg}`}
      >
        <FontAwesomeIcon
          className={`mr-2 h-4 ${colors[type].text}`}
          icon={icons[type]}
        />
        <p className={`font-medium text-sm ${colors[type].text}`}>{message}</p>
        <button
          className="ml-auto text-white hover:text-red-900 dark:hover:text-red-400"
          onClick={handleClose}
        >
          <FontAwesomeIcon className="h-4" icon={faTimesCircle} />
        </button>
      </div>
    </div>
  )
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired
}

const ReplyingTo = ({ message, handleClose }) => {
  const [height, setHeight] = useState(0)
  const replyRef = useRef(null)

  useEffect(() => {
    setHeight(replyRef.current.clientHeight)
  })

  return (
    <div className="relative mb-2 bg-blue-600 dark:bg-blue-900 transition-all rounded-lg w-full">
      <button className="button-x" onClick={() => handleClose(null)}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div
        ref={replyRef}
        style={{ top: -height }}
        className="flex flex-col flex-grow items-center justify-start gap-1 py-2"
      >
        <div className="px-2 w-full flex items-center text-white dark:text-blue-200">
          <div className="flex items-center gap-2">
            <span className="text-xs italic">
              <FontAwesomeIcon
                className="mr-1.5 transition-all"
                icon={faReply}
              />
              {`Replying to: ${modNameChanger(message)}`}
            </span>
          </div>
        </div>
        <div className="px-2 w-96 mr-auto">
          <p className="font-medium text-white dark:text-blue-200 text-xs overflow-auto truncate">
            {message.message}
          </p>
        </div>
      </div>
    </div>
  )
}

ReplyingTo.propTypes = {
  message: PropTypes.object.isRequired,
  handleClose: PropTypes.func
}

export default ChatForm
