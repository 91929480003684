import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import Footer from '../../components/Layout/Footer'
import WistiaPlayer from '../../components/Utilities/WistiaPlayer'
import Logo from '../../graphics/Logo'
import { putUser } from '../../utils/APIs/users'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { setPageTitle } from '../../utils/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignIn } from '@fortawesome/pro-solid-svg-icons'

const WelcomeVideo = () => {
  setPageTitle('Welcome')
  const { user, updateUser } = useContext(AuthenticationContext)
  const { [SETTINGS.WELCOME_VIDEO.key]: welcomeVideo } =
    useContext(SiteConfigContext)

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!user) {
      navigate('/login')
      return
    }
    const response = await putUser(user._id, 'onboarding.welcome', true)

    if (response && response.status === 200) {
      updateUser(response.data)
      navigate(from, { replace: true })
    }
  }

  return (
    <main className="flex flex-col h-screen overflow-y-auto bg-primary-gradient sm:p-2.5 gap-2.5 md:gap-5">
      <div className="h-8 md:h-12 mx-auto mt-2.5 md:mt-5">
        <Logo />
      </div>
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-2.5 md:gap-5">
        <div className="col-span-1 lg:col-span-2 h-fit shadow-lg">
          <WistiaPlayer
            videoId={welcomeVideo.wistiaId}
            wrapper="welcome-video"
          />
        </div>
        <div className="col-span-1 lg:col-span-1 h-fit shadow-lg p-2.5 md:p-5 bg-white dark:bg-neutral-900 md:rounded-xl flex flex-col gap-2.5 md:gap-5">
          <h1 className="text-center md:text-left font-bold">Welcome!</h1>
          <p className="w-fit text-lg">{welcomeVideo.blurb}</p>
          <form onSubmit={handleSubmit} autoComplete="off">
            <button
              type="submit"
              aria-label="Submit message"
              className="button-fill my-2.5 p-2.5 flex items-center justify-center md:justify-start md:text-lg w-full md:w-fit"
            >
              Enter The Stream
              <FontAwesomeIcon icon={faSignIn} className="ml-2" />
            </button>
          </form>
        </div>
      </div>
      <div className="mt-auto">
        <Footer />
      </div>
    </main>
  )
}

export default WelcomeVideo
