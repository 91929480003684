import React from 'react'
import { setPageTitle } from '../../../utils/utilities'
import PrivateMessagesPerDay from './PrivateMessagesPerDay'
import PublicMessagesPerDay from './PublicMessagesPerDay'
import UniqueUsersPerDay from './UniqueUsersPerDay'
import UniqueUsersPerDayExport from './UniqueUsersPerDayExport'
import UserCounts from './UserCounts'
import ViewsPerDay from './ViewsPerDay'
import ViewsPerHour from './ViewsPerHour'

const Stats = () => {
  setPageTitle('Stats')
  return (
    <div className="flex flex-col w-full gap-4 pb-4 md:grid md:grid-cols-2">
      <PublicMessagesPerDay />
      <PrivateMessagesPerDay />
      <ViewsPerHour />
      <ViewsPerDay />
      <UniqueUsersPerDay />
      <UserCounts />
      <UniqueUsersPerDayExport />
    </div>
  )
}

export default Stats
