import React from 'react'
import UserBadges from '../../Utilities/UserBadges'
import UserInfoWidget from '../UserInfoWidget'
import { modNameChanger } from '../../../utils/utilities'
import PropTypes from 'prop-types'

const UserName = ({
  message,
  user,
  expandedUserInfoWidget,
  setExpandedUserInfoWidget,
  modView
}) => {
  const handleOnClick = () =>
    modView
      ? null
      : setExpandedUserInfoWidget(
          expandedUserInfoWidget === message._id ? null : message._id
        )

  return (
    <div className="inline-flex gap-1 items-center mr-1">
      <span
        className={`text-${message.user.userService.profile.color} font-bold`}
        onClick={handleOnClick}
      >
        <UserBadges
          role={message.user.role.role}
          user={message.user}
          registrationDate={message.user.registrationDate}
        />
        {modNameChanger(message)}:
      </span>
      {!modView && expandedUserInfoWidget === message._id && (
        <UserInfoWidget
          user={user}
          message={message}
          setExpandedUserInfoWidget={setExpandedUserInfoWidget}
        />
      )}
    </div>
  )
}

UserName.propTypes = {
  message: PropTypes.object.isRequired,
  user: PropTypes.object,
  expandedUserInfoWidget: PropTypes.string,
  setExpandedUserInfoWidget: PropTypes.func,
  modView: PropTypes.bool
}

export default UserName
