import { ANNOUNCEMENT_EVENTS, getUnreadCount } from '@moal/api'
import React, { useContext, useEffect, useState } from 'react'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SocketContext } from '../../../context/socketContext'
import { PRODUCT_CODE } from '../../../utils/ENV'
import { NotificationBadge } from '../NotificationBadge'

const UnreadCountBadge = () => {
  const { user } = useContext(AuthenticationContext)
  const { announcementsSocket } = useContext(SocketContext)
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    if (announcementsSocket) {
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.POST, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.EDIT, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.DELETE, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.READ, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.READ_ALL, resetCount)
    }

    return () => {
      if (announcementsSocket) {
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.POST, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.EDIT, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.DELETE, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.READ, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.READ_ALL, resetCount)
      }
    }
  }, [announcementsSocket])

  useEffect(() => {
    if (user) {
      fetchUnreadCount()
    }
  }, [user])

  const resetCount = () => {
    setUnreadCount(0)
  }

  const fetchUnreadCount = async () => {
    try {
      const response = await getUnreadCount({
        token: localStorage.getItem('authToken'),
        latest: true,
        itemNumber: PRODUCT_CODE
      })
      setUnreadCount(response.data.count)
    } catch (error) {
      console.error(error)
    }
  }

  return <NotificationBadge count={unreadCount} />
}

export default UnreadCountBadge
