import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import RenameForm from '../../components/Forms/RenameForm'
import Logo from '../../graphics/Logo'
import { replaceDefaultColor } from '../../utils/colors'
import { setPageTitle } from '../../utils/utilities'
import Footer from '../../components/Layout/Footer'

const Register = () => {
  setPageTitle('Register')
  const { user, updateUser } = useContext(AuthenticationContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      replaceDefaultColor(user, updateUser)
    }
  }, [user])

  useEffect(() => {
    if (user && user.userService.profile.name !== '') {
      navigate('/', { replace: true })
    }
  }, [user])

  return (
    <main className="flex flex-col h-screen overflow-y-auto xl:p-5 bg-primary-gradient">
      <div className="flex flex-col gap-5 lg:w-2/3 xl:w-1/2 justify-center mb-auto md:m-auto">
        <div className="container sm:rounded-lg bg-neutral-100 dark:bg-neutral-900 w-fit mx-auto p-5 shadow-lg">
          <div className="flex flex-col justify-between items-center gap-5">
            <div className="h-16 w-fit mx-auto my-5">
              <Logo />
            </div>
            <h1 className="my-2 md:my-6 text-center font-bold tracking-wide">
              Register Display Name
            </h1>
            <div className="flex flex-col w-fit justify-center">
              <div className="w-full max-w-96">
                <RenameForm user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Register
