import { faBell, faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react'
import { SINGLE_APP_PRODUCT_CODE } from '@moal/api'
import React, { useContext } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { LatestAnnouncement } from '../../../LatestAnnouncement'
import AnnouncementsList from '../../../Utilities/AnnouncementsList/AnnouncementsList'
import UnreadCountBadge from '../../../Utilities/UnreadCountBadge/UnreadCountBadge'

export default function AnnoucementFeed() {
  const { [SETTINGS.HUB_BUTTON.key]: hubButton } = useContext(SiteConfigContext)

  const isSingleApp = SINGLE_APP_PRODUCT_CODE || false

  return (
    <LatestAnnouncement>
      {({ latestAnnouncement, markLatestAnnouncementRead }) => {
        return (
          <Popover className="relative">
            <PopoverButton
              className="header-button button-outline"
              aria-label="Announcements"
              disabled={!latestAnnouncement}
              onClick={markLatestAnnouncementRead}
            >
              <FontAwesomeIcon
                icon={faBell}
                className="w-3.5 h-3.5 md:w-5 md:h-5"
              />
              <UnreadCountBadge />
            </PopoverButton>

            {latestAnnouncement && (
              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="fixed origin-top-right top-10 right-0 md:top-14 md:right-2 w-full max-w-[720px] z-50 overflow-auto md:rounded-xl bg-white shadow-lg ring-1 ring-black/20 dark:ring-black/50 focus:outline-none dark:bg-neutral-900">
                  <div className="w-full rounded-xl">
                    <div className="flex flex-col gap-2.5 md:gap-5 p-2.5 md:p-5 w-full">
                      <h2>Latest Announcement</h2>
                      <AnnouncementsList announcements={[latestAnnouncement]} />
                      {isSingleApp === 'true' ? null : (
                        <a
                          className="button-outline w-full md:w-fit flex justify-center items-center gap-2"
                          href={`${hubButton.link}announcements`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Announcement Feed
                          <FontAwesomeIcon
                            icon={faExternalLink}
                            className="w-3 h-3 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </PopoverPanel>
              </Transition>
            )}
          </Popover>
        )
      }}
    </LatestAnnouncement>
  )
}
