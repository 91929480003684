import { PRODUCT_TYPES } from '@moal/api'
import React, { useContext, useEffect, useState } from 'react'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { setPageTitle } from '../../../../utils/utilities'
import Free from './Free'
import OpenHouse from './OpenHouse'

const Product = () => {
  setPageTitle('Configuration | Product')
  const { productsService } = useContext(SiteConfigContext)
  const [current, setCurrent] = useState()

  useEffect(() => {
    if (productsService) {
      setCurrent(productsService[PRODUCT_TYPES.PRODUCT])
    }
  }, [productsService])

  const updateState = (data) => {
    setCurrent(data)
  }

  if (!current) {
    return null
  }

  return (
    <div className="flex flex-col gap-5">
      <h2>Product</h2>
      <OpenHouse product={current} updateState={updateState} />
      {current.details.type === PRODUCT_TYPES.FREE && (
        <Free product={current} updateState={updateState} />
      )}
    </div>
  )
}

export default Product
