import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import TermsAndConditionsForm from '../../components/Forms/TermsAndConditionsForm'
import Logo from '../../graphics/Logo'
import Footer from '../../components/Layout/Footer'
import { setPageTitle } from '../../utils/utilities'

const TermsAndConditions = () => {
  setPageTitle('Terms & Conditions')
  const { user } = useContext(AuthenticationContext)
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <main className="flex flex-col h-screen overflow-y-auto xl:p-5 bg-primary-gradient">
      <div className="flex flex-col gap-5 lg:w-2/3 xl:w-1/2 justify-center mb-auto md:m-auto">
        <div className="container sm:rounded-lg bg-neutral-100 dark:bg-neutral-900 w-full mx-auto p-5 shadow-lg">
          <div className="flex flex-col justify-between items-center gap-5">
            <div className="h-16 w-fit mx-auto my-5">
              <Logo />
            </div>
            <TermsAndConditionsForm
              disable={user && user.onboarding.terms === true}
            />
            {user && user.onboarding.terms === true && (
              <button className="button-outline" onClick={handleGoBack}>
                ← Go Back
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default TermsAndConditions
