import axios from 'axios'
import { API } from '../ENV'

// Views
export const getViewCounts = async (type, start, end) => {
  try {
    const response = await axios.get(`${API}/views/${type}/${start}/${end}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
