import React from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Layout/Footer'
import Logo from '../../graphics/Logo'
import { PRODUCT_NAME } from '../../utils/ENV'
import { setPageTitle } from '../../utils/utilities'

const NotFound = () => {
  setPageTitle('Not Found')
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <main className="flex flex-col h-screen overflow-y-auto dark:bg-black">
      <div className="mx-auto mb-auto">
        <div className="my-4 cursor-pointer" onClick={handleGoBack}>
          <Logo />
        </div>
      </div>
      <div className="my-auto">
        <div className="container sm:rounded-xl w-fit mx-auto p-4 md:p-20">
          <div className="flex flex-col justify-between items-center gap-4">
            <h1 className="text-8xl font-bold border-b pb-4 text-white">
              4🌒4
            </h1>
            <h2 className="text-2xl font-bold text-white">Page Not Found</h2>
            <div className="w-full sm:w-96 flex flex-col gap-3">
              <p className="text-white">
                Oops! It looks like the page you were looking for has gone
                missing or does not exist. Our team of developers is on the case
                and working hard to restore it (unless, of course, it does not
                exist).
              </p>
              <p className="text-white">
                In the meantime, you can
                <span
                  className="whitespace-nowrap cursor-pointer text-green-500 mx-2"
                  onClick={handleGoBack}
                >
                  [ ← Return Home ]
                </span>
                to get back to {PRODUCT_NAME}.
              </p>
              <p className="text-white">
                Thanks for your patience and understanding. We apologize for any
                inconvenience this may have caused.
              </p>
              <button className="button-fill mx-auto" onClick={handleGoBack}>
                ← Return Home
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default NotFound
